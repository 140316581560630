import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Radio from "@mui/material/Radio";
import MDBox from "components/MDBox";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EastIcon from "@mui/icons-material/East";
const options = [
  {
    label: "Most Active Vendor",
    value: "1",
  },
  {
    label: "Least Active Vendor",
    value: "2",
  },
  {
    label: "Vendors who Logged in on Web",
    value: "3",
  },
  {
    label: "Vendors who Logged in on Mobile",
    value: "4",
  },
  {
    label: "Vendors who Signed up on Desktop",
    value: "5",
  },
  {
    label: "Vendors who have not setup a Store",
    value: "6",
  },
  {
    label: "Vendors who signed up using Referral Codes",
    value: "7",
  },
  {
    label: "Vendors who have not added a Product",
    value: "8",
  },
];
export default function SelectUserFilter() {
  const [selected, setSelected] = React.useState("");

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button
            variant="outlined"
            {...bindTrigger(popupState)}
            sx={{
              minWidth: 120,
              display: "flex",
              justifyContent: "space-between",
              p: 1.5,
              color: "#000",
              border: "#CED9E8 solid 1px",
              ":hover": {
                border: "gray solid 1px",
              },
            }}
          >
            Filter By {popupState.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
          <Menu {...bindMenu(popupState)}>
            {options.map((o) => (
              <MenuItem value={o.value}>
                <Radio
                  checked={selected === "10"}
                  onChange={handleChange}
                  value="a"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                {o.label}
              </MenuItem>
            ))}
            <MDBox sx={{ px: 3, py: 2 }}>
              <Button
                // color="#0230B1"
                variant="contained"
                // {...bindTrigger(popupState)}
                sx={{ width: "100%", p: 1.5, color: "#fff", backgroundColor: "#0230B1" }}
              >
                Next <EastIcon sx={{ ml: 1 }} />
              </Button>
            </MDBox>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
