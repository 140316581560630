import DataTable from "examples/Tables/DataTable";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import { Icon } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import GroupServices from "services/GroupServices";
import "../pagination.css";
import Holder from "../tableholder";

function GroupPermission() {
  const columns = [
    { Header: "Group Name", accessor: "name" },
    { Header: "description", accessor: "description" },
    { Header: "", accessor: "action", align: "right" },
  ];

  const [group, setGroup] = useState([]);
  const [data, setData] = useState({
    name: "",
    description: "",
  });
  const rows = group.map((item) => ({
    ...item,
    action: (
      <div>
        <Button onClick={() => deleteGroups(item.id)}>Revoke Access</Button>
      </div>
    ),
  }));
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchGroups(page, size);
  }, []);

  const onChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleDialogOpen = () => {
    setOpen((prev) => !prev);
  };

  const fetchGroups = async () => {
    setLoading(true);
    const groupList = await GroupServices.getGroups();
    setLoading(false);
    console.log({ groupList });
    if (groupList.status === 200) {
      setGroup(groupList.data?.data?.groups);
    } else {
      toast.error("Unable to fetch Groups", {
        position: "bottom-left",
      });
    }
  };

  const addGroups = async () => {
    toast.loading("Creating New Group....");
    const group = await GroupServices.addGroups(data);
    // console.log({ group });
    toast.dismiss();
    const message = group?.data?.message || group?.data?.data?.message;
    if (group.status === 200) {
      toast.success(message);
      handleDialogOpen();
      setData({
        name: "",
        description: "",
      });
      fetchGroups();
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  const deleteGroups = async (id) => {
    toast.loading("Deleting Group....");
    const group = await GroupServices.deleteGroups(id);
    // console.log({ group });
    toast.dismiss();
    const message = group?.data?.message || group?.data?.data?.message;
    if (group.status === 200) {
      toast.success(message);
      handleDialogOpen();
      fetchGroups();
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  return (
    <>
      <MDBox>
        <MDBox>
          <div style={{ display: "block" }}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDTypography variant="h6" gutterBottom>
                  Groups
                </MDTypography>
                <div>
                  <MDButton variant="gradient" color="dark" onClick={handleDialogOpen}>
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp; create Group
                  </MDButton>
                </div>
              </MDBox>
              {loading ? (
                <Holder />
              ) : (
                <DataTable
                  className="hidden "
                  table={{ columns, rows }}
                  showTotalEntries={false}
                  isSorted={false}
                  size={size}
                  entriesPerPage={false}
                  pagination={false}
                />
              )}
            </Card>
          </div>
        </MDBox>
      </MDBox>

      <Dialog open={open} onClose={handleDialogOpen} fullWidth>
        <DialogTitle>Create Group</DialogTitle>
        <DialogContent>
          {/* <DialogContentText></DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="notification"
            label="Group Name"
            type="text"
            fullWidth
            variant="standard"
            name="name"
            value={data.name}
            onChange={onChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="notification"
            label="Group Description"
            type="text"
            fullWidth
            variant="standard"
            rows={3}
            multiline
            name="description"
            value={data.description}
            onChange={onChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button disabled={data.name === ""} onClick={addGroups}>
            Save Group
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default GroupPermission;
