import requests from "components/api";

const AssetServices = {
  async getAsset(asset_Type, { page = 0, size = 20, search = "" }) {
    const res = await requests.get(
      `/accounts/api/v1/assets/type/${asset_Type}?page=${page}&size=${size}&q=${search}`
    );
    return res;
  },

  async getAssetById(id) {
    const res = await requests.get(`/accounts/api/v1/assets/${id}`);
    return res;
  },

  async postAsset(payload) {
    const res = await requests.post(`/accounts/api/v1/assets`, payload);
    return res;
  },

  async putAsset(payload) {
    const res = await requests.put(`/accounts/api/v1/assets`, payload);
    return res;
  },

  async deleteAsset(id) {
    const res = await requests.delete(`/accounts/api/v1/assets/${id}`);
    return res;
  },

  async uploadAsset(payload) {
    const res = await requests.post(`/accounts/api/v1/assets/upload`, payload);
    return res;
  },

  async updateUpload(payload) {
    const res = await requests.put(`/accounts/api/v1/assets/upload`, payload);
    return res;
  },
};

export default AssetServices;
