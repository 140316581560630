import { Card, Icon, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useAssetSubmit from "hooks/useAssetSubmit";
import JoditEditor from "jodit-react";
import { useMemo, useRef } from "react";

export default function HelpsCreateEdit({ placeholder }) {
  const query = new URLSearchParams(window.location.search);
  const id = query.get("id");
  const { asset, content, setContent, onChange, onsubmit } = useAssetSubmit({
    id,
    asset_Type: "helps_and_hints",
  });

  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/,
      placeholder: placeholder || "Start typings...",
      height: 600,
    }),
    [placeholder]
  );

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <div style={{ display: "block" }}>
              <Card>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <MDTypography variant="h6" gutterBottom>
                    {id ? "Update" : "Save"} Help and Hint
                  </MDTypography>
                  <div>
                    <MDButton variant="gradient" color="dark" onClick={onsubmit}>
                      <Icon sx={{ fontWeight: "bold" }}>save</Icon>
                      &nbsp; {id ? "Update" : "Save"} Helps
                    </MDButton>
                  </div>
                </MDBox>
                <MDBox px={8} pb={10}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="preset"
                    label="Unique Preset Code"
                    type="text"
                    fullWidth
                    variant="standard"
                    name="preset_code"
                    value={asset.preset_code}
                    onChange={onChange}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="description"
                    label="Description"
                    type="text"
                    fullWidth
                    variant="standard"
                    name="description"
                    value={asset.description}
                    onChange={onChange}
                  />

                  <TextField
                    autoFocus
                    margin="dense"
                    id="notification"
                    label="Tags"
                    type="text"
                    fullWidth
                    variant="standard"
                    name="tags"
                    value={asset.tags}
                    onChange={onChange}
                  />
                  <br />

                  <MDTypography variant="button" fontWeight="regular" color="text">
                    Content
                  </MDTypography>

                  <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    tabIndex={1}
                    onBlur={(newContent) => setContent(newContent)}
                    onChange={(newContent) => {}}
                  />
                </MDBox>
              </Card>
            </div>
          </MDBox>
        </MDBox>
      </DashboardLayout>
    </div>
  );
}
