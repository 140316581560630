import { useState } from "react";

export default function useFilter() {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState();
  const handlePageClick = async (e) => {
    const newpage = e.selected;
    setPage(newpage);
  };

  const changeSize = (pagesize) => {
    if (pagesize == "") {
      return;
    }
    setSize(pagesize);
  };

  return {
    changeSize,
    page,
    setPage,
    size,
    setSize,
    pageCount,
    setPageCount,
    total,
    setTotal,
    search,
    setSearch,
    handlePageClick,
  };
}
