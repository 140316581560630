import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { Link } from "react-router-dom";

const ITEM_HEIGHT = 48;

export default function LongMenu({ data, setAttributes, setOpenAttributes }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "25ch",
          },
        }}
      >
        <Link to={`/users/edit?id=${data.id}`}>
          <MenuItem>Attributes</MenuItem>
        </Link>
        <Link to={`/emailing/send?email=${data.email}`}>
          {" "}
          <MenuItem>Send Mail</MenuItem>
        </Link>
        <MenuItem onClick={handleClose}>Send Notification</MenuItem>
        <MenuItem onClick={handleClose}>Send SMS</MenuItem>
        <MenuItem onClick={handleClose} sx={{ color: "#AC2E0E" }}>
          Disable User
        </MenuItem>
      </Menu>
    </div>
  );
}
