import { useEffect, useState } from "react";

import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { getAsset } from "components/api";
import GroupUser from "./GroupUsers";
import GroupPermission from "./GroupPermision";
export default function GroupEdit() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => setValue(newValue);

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  const getAssets = async () => {
    try {
      const { data } = await getAsset("others");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAssets();
  }, []);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox position="relative" px={2}>
          <Card
            sx={{
              py: 2,
              px: 2,
            }}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <MDTypography variant="h5" fontWeight="medium">
                  Group Title
                </MDTypography>
              </Grid>
              <Grid item xs={12} md={6} lg={6} sx={{ ml: "auto" }}>
                <AppBar position="static">
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {/* <Tab label="Images" {...a11yProps(0)} /> */}
                    <Tab label="Users" {...a11yProps(1)} />
                    <Tab label="Permission" {...a11yProps(2)} />
                  </Tabs>
                </AppBar>
              </Grid>
            </Grid>
          </Card>
        </MDBox>
        <MDBox mb={5}>
          <TabPanel value={value} index={0}>
            <GroupUser />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <GroupPermission />
          </TabPanel>
        </MDBox>
      </DashboardLayout>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
