import requests from "components/api";

const GroupServices = {
  async getGroups() {
    const res = await requests(`/accounts/api/v1/apps/groups`);
    return res;
  },

  async addGroups(payload) {
    const res = await requests(`/accounts/api/v1/apps/groups`, payload);
    return res;
  },

  async editGroups(payload) {
    const res = await requests(`/accounts/api/v1/apps/groups`, payload);
    return res;
  },

  async deleteGroups(payload) {
    const res = await requests(`/accounts/api/v1/apps/groups`, payload);
    return res;
  },

  //users
  async getGroupsUsers(id) {
    const res = await requests(`/accounts/api/v1/apps/groups/${id}/users`);
    return res;
  },
};

export default GroupServices;
