import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Radio from "@mui/material/Radio";
import MDBox from "components/MDBox";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import { Typography } from "@material-ui/core";
const options = [
  {
    label: "Last 5 days ",
    value: "1",
  },
  {
    label: "Last 7 days ",
    value: "2",
  },
  {
    label: "Last 15 days ",
    value: "3",
  },
  {
    label: "Last 30 days ",
    value: "4",
  },
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function FilterByDate() {
  const [selected, setSelected] = React.useState("");
  const [value, setValue] = React.useState(0);
  const [showCustom, setShowCustom] = React.useState(false);
  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button
            variant="outlined"
            {...bindTrigger(popupState)}
            sx={{
              minWidth: 150,
              display: "flex",
              justifyContent: "space-between",
              p: 1.5,
              color: "#000",
              border: "#CED9E8 solid 1px",
              ":hover": {
                border: "gray solid 1px",
              },
            }}
          >
            Filter by date {popupState.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>

          <Menu {...bindMenu(popupState)}>
            {showCustom && (
              <Button sx={{ p: 0, m: 0, color: "#0230B1" }} onClick={() => setShowCustom(false)}>
                Back
              </Button>
            )}
            <Tabs
              sx={{ mb: 2 }}
              value={value}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Last seen" {...a11yProps(0)} />
              <Tab label="Date created" {...a11yProps(1)} />
            </Tabs>

            {showCustom ? (
              <MDBox>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    py: 2,
                    borderTop: "1px solid #CED9E8",
                  }}
                >
                  <TextField
                    type="date"
                    sx={{ width: "125px", fontWeight: 400, fontSize: "13px" }}
                  />
                  <Typography>to</Typography>
                  <TextField
                    type="date"
                    sx={{ width: "125px", fontWeight: 400, fontSize: "13px" }}
                  />
                </MDBox>
                <MDBox>
                  <Button
                    variant="contained"
                    onClick={() => setShowCustom(true)}
                    sx={{ width: "100%", p: 1.5, backgroundColor: "#0230B1", color: "#fff" }}
                  >
                    Apply
                  </Button>
                </MDBox>
              </MDBox>
            ) : (
              <MDBox>
                {options.map((o) => (
                  <MenuItem value={o.value} sx={{ p: 0 }}>
                    <Radio
                      checked={selected === "10"}
                      onChange={handleChange}
                      value="a"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                    />
                    {o.label}
                  </MenuItem>
                ))}
                <MDBox sx={{ px: 3 }}>
                  <Button
                    variant="text"
                    onClick={() => setShowCustom(true)}
                    sx={{ width: "100%", p: 1.5, color: "#0230B1" }}
                  >
                    Select Custom Range
                  </Button>
                </MDBox>
              </MDBox>
            )}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
