import DataTable from "examples/Tables/DataTable";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import { Icon, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import "./pagination.css";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DialogContentText from "@mui/material/DialogContentText";
import { deleteAsset } from "components/api";

import useAsync from "hooks/useAsync";
import useFilter from "hooks/useFilter";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import AssetServices from "services/assetServices";
import Holder from "./tableholder";
function HintsAndHelp() {
  const [open, setOpen] = useState(false);
  const [templates, setTemplates] = useState([]);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const {
    changeSize,
    page,
    size,
    pageCount,
    setPageCount,
    total,
    setTotal,
    search,
    setSearch,
    handlePageClick,
  } = useFilter();
  const { data, loading, setIsUpdated } = useAsync(AssetServices.getAsset, {
    params: "helps_and_hints",
    page,
    size,
    search,
    setPageCount,
    setTotal,
  });

  console.log({ data });

  useEffect(() => {
    const getTemplates = async () => {
      try {
        setTemplates(data.data?.data?.content?.sort((a, b) => a.deletable - b.deletable));
      } catch (err) {
        console.log(err);
      }
    };

    if (data.data?.data?.content) {
      getTemplates();
    }
  }, [data]);

  const rows = templates?.map((item) => ({
    ...item,
    action: (
      <div>
        <Link to={`/helps/edit?id=${item.id}`}>
          <Button>
            <EditIcon sx={{ color: "gray" }} />
          </Button>
        </Link>

        <Button
          onClick={() => {
            setDeleteId(item.id);
            handleDeleteOpen(item.id);
          }}
        >
          <DeleteIcon sx={{ color: "red" }} />
        </Button>
      </div>
    ),
  }));

  const columns = [
    { Header: "Preset code", accessor: "preset_code", align: "left" },
    { Header: "description", accessor: "description" },
    { Header: "tags", accessor: "tags" },
    { Header: "", accessor: "action", align: "right" },
  ];

  const handleDialogOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen((prev) => !prev);
  };

  const _deleteAsset = async () => {
    toast.loading("Deleting template....");
    const _template = await deleteAsset(deleteId);
    toast.dismiss();
    handleDeleteOpen();
    setIsUpdated(true);
    setDeleteId("");
    const message = _template?.data?.message || _template?.data?.data?.message;
    if (_template.status === 200) {
      toast.success(message);
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <div style={{ display: "block" }}>
              <Card>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <MDTypography variant="h6" gutterBottom>
                    Helps / Hints
                  </MDTypography>
                  <div>
                    <Link to="/helps/create">
                      <MDButton variant="gradient" color="dark" onClick={handleDialogOpen}>
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                        &nbsp; create Help/Hints
                      </MDButton>
                    </Link>
                  </div>
                </MDBox>
                {loading ? (
                  <Holder />
                ) : (
                  <DataTable
                    className="hidden"
                    table={{ columns, rows }}
                    showTotalEntries={false}
                    isSorted={false}
                    size={size}
                    noEndBorder
                    entriesPerPage={false}
                    style={{ display: "none" }}
                    pagination={false}
                  />
                )}

                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <MDTypography variant="h6" gutterBottom>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={<i className="fa fa-angle-double-right"></i>}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel={<i className="fa fa-angle-double-left"></i>}
                      containerClassName="pagination"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      activeClassName="active"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      renderOnZeroPageCount={false}
                    />
                  </MDTypography>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    lineHeight={0}
                    justifyContent="space-between"
                  >
                    <div>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;Showing <strong>{data.length} </strong> users of {total}
                      </MDTypography>
                    </div>
                    <div>
                      <select
                        className="select-size"
                        onChange={(e) => changeSize(e.target.value)}
                        value={size}
                      >
                        <option value="">Page Size</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </MDBox>
                </MDBox>
              </Card>
            </div>
          </MDBox>
        </MDBox>
      </DashboardLayout>

      <Dialog open={deleteOpen} onClose={handleDeleteOpen} fullWidth>
        <DialogTitle>Delete Help / Hint</DialogTitle>

        <DialogContent>
          <DialogContentText>Are You sure you want to delete this asset</DialogContentText>{" "}
          <Typography sx={{ fontSize: "14px" }}>This will erase the record permanently</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={_deleteAsset}>Yes Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default HintsAndHelp;
