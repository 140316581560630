import { Button, Card, Icon, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { getAsset, deleteAsset } from "components/api";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export default function HTML() {
  const [size, setSize] = useState(20);
  const [open, setOpen] = useState(false);
  const [serviceId, setServiceId] = useState("");
  const [HTMLs, setHtmls] = useState([]);
  const handleDialogOpen = (id) => {
    if (serviceId) {
      setServiceId("");
    } else {
      setServiceId(id);
    }
    setOpen((prev) => !prev);
  };

  const rows = HTMLs.map((item) => ({
    ...item,
    action: (
      <div>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(item.url);
            toast.success("Asset URL copied to clipboard");
          }}
        >
          Copy
        </Button>
        <Link to={`/assets/html/edit?id=${item.id}`}>
          <Button>Edit</Button>
        </Link>
        <Button onClick={() => handleDialogOpen(item.id)}>Delete</Button>
      </div>
    ),
  }));

  const columns = [
    { Header: "Description", accessor: "description", align: "left" },
    { Header: "Tags", accessor: "tags" },
    { Header: "", accessor: "action", align: "right" },
  ];

  const deleteTemplate = async () => {
    toast.loading("Deleting template....");
    const _template = await deleteAsset(serviceId);
    toast.dismiss();
    handleDialogOpen();
    getAssets();
    const message = _template?.data?.message || _template?.data?.data?.message;
    if (_template.status === 200) {
      toast.success(message);
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  const getAssets = async () => {
    try {
      const { data } = await getAsset("others?exts=html");
      setHtmls(data.data?.content);

      // console.log({ data });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAssets();
  }, []);

  return (
    <>
      <MDBox>
        <MDBox>
          <div style={{ display: "block" }}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDTypography variant="h6" gutterBottom>
                  Total HTML {HTMLs.length}
                </MDTypography>
                <div>
                  <Link to="/assets/HTML/create">
                    <MDButton variant="gradient" color="dark">
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp; Add HTML
                    </MDButton>{" "}
                  </Link>
                </div>
              </MDBox>
              <DataTable
                className="hidden"
                table={{ columns, rows }}
                showTotalEntries={false}
                isSorted={false}
                size={size}
                noEndBorder
                entriesPerPage={false}
                style={{ display: "none" }}
                pagination={false}
              />
            </Card>
          </div>
        </MDBox>
      </MDBox>

      <Dialog open={open} onClose={handleDialogOpen} fullWidth>
        <DialogTitle>Delete HTML</DialogTitle>

        <DialogContent>
          <DialogContentText>Are You sure you want to delete this asset</DialogContentText>{" "}
          <Typography sx={{ fontSize: "14px" }}>This will erase the record permanently</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={deleteTemplate}>Yes Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
