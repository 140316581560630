import { Card, Icon } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { getAssetById, postAsset, putAsset } from "components/api";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import JoditEditor from "jodit-react";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

const pages = [
  {
    title: "Terms and Conditions",
    type: "terms",
  },
  {
    title: "Privacy Policy",
    type: "privacy",
  },
  {
    title: "Refund Policy",
    type: "refund",
  },
  {
    title: "Brand Story",
    type: "brand_story",
  },
  {
    title: "Data Policy",
    type: "data_policy",
  },
  {
    title: "Data Retention",
    type: "data_retention",
  },
];

export default function CustomPageCreateEdit() {
  const query = new URLSearchParams(window.location.search);
  const id = query.get("id");

  const [asset, setAsset] = useState({
    description: "",
    ext: "html",
    tags: "",
    asset_type: "custom_page",
    preset_code: null,
    is_public: true,
  });

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const handleChange = (event) => {
    const data = event.target.value;
    const preset_code = data.replace(/\W+/g, "-").toLowerCase();
    setAsset({ ...asset, description: data, preset_code });
  };

  useEffect(() => {
    if (!asset.description) return;
    const page = pages.find((i) => i.title === asset.description);
    setAsset({ ...asset, tags: page.type });
  }, [asset.description]);

  const config = {
    readonly: false,
    height: 600,
  };

  useEffect(() => {
    setAsset((prev) => ({
      ...prev,
      slug: asset.description?.trim().split(" ").join("-"),
    }));
  }, [asset.description]);

  useEffect(() => {
    if (id) {
      _getAssetById(id);
    }
  }, []);

  // get By id
  const _getAssetById = async (id) => {
    try {
      toast.loading("Loading template....");
      const { data } = await getAssetById(id);
      toast.dismiss();
      console.log({ blog: data });
      if (data.code === 200) {
        setContent(data.data?.asset.content);

        setAsset({
          hero_image_url: data.data?.asset.hero_image_url,
          description: data.data?.asset.description,
          ext: data.data?.asset.ext,
          tags: data.data?.asset.tags, // use tags input
          asset_type: asset.data?.asset.asset_type,
          preset_code: data.data?.asset.preset_code,
          is_public: data.data?.asset.is_public,
        });
      } else {
        toast.error("Unknown error Occured", {
          position: "bottom-left",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addAsset = async () => {
    if (!asset.description) {
      toast.error("Page Title is Required");
      return;
    }
    toast.loading("Adding template....");

    const _template = await postAsset({ ...asset, content });
    toast.dismiss();
    const message = _template?.data?.message || _template?.data?.data?.message;
    if (_template.status === 200) {
      toast.success(message);
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  const updateAsset = async () => {
    toast.loading("Adding template....");
    const _template = await putAsset({ ...asset, content, id });
    toast.dismiss();
    const message = _template?.data?.message || _template?.data?.data?.message;
    if (_template.status === 200) {
      toast.success(message);
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <div style={{ display: "block" }}>
              <Card>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <MDTypography variant="h6" gutterBottom>
                    {id ? "Update" : "Save"} Page
                  </MDTypography>
                  <div>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={id ? updateAsset : addAsset}
                      // disabled={!Post.name || !Post.content}
                    >
                      <Icon sx={{ fontWeight: "bold" }}>save</Icon>
                      &nbsp; {id ? "Update" : "Save"} Page
                    </MDButton>
                  </div>
                </MDBox>
                <MDBox px={8} pb={10}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Page Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={asset.description}
                      label="Page Type"
                      onChange={handleChange}
                      sx={{ py: 2 }}
                    >
                      {pages.map((p) => (
                        <MenuItem value={p.title}>{p.title}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <MDTypography variant="button" fontWeight="regular" color="text">
                    Content
                  </MDTypography>

                  <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    tabIndex={1}
                    onBlur={(newContent) => setContent(newContent)}
                    onChange={(newContent) => {}}
                  />
                </MDBox>
              </Card>
            </div>
          </MDBox>
        </MDBox>
      </DashboardLayout>
    </div>
  );
}
