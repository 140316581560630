import { createContext, useState } from "react";

const UIContext = createContext();

export function UIContextProvider({ children }) {
  const [isUpdated, setIsUpdated] = useState();

  const value = { isUpdated, setIsUpdated };
  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
}

export default UIContext;
