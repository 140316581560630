import { Icon, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import { deleteAsset, getAsset, getAssetById, uploadAsset } from "components/api";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Holder from "./tableholder";

const exts = [
  {
    label: "PDF",
    value: "pdf",
  },
  {
    label: "DOCS",
    value: "docs",
  },
  {
    label: "XLS",
    value: "xls",
  },
  {
    label: "XLXS",
    value: "xlxs",
  },
  {
    label: "ODS",
    value: "ods",
  },
  {
    label: "TXT",
    value: "txt",
  },
  {
    label: "JSON",
    value: "json",
  },
];
export default function Others() {
  const [open, setOpen] = useState(false);
  const [assets, setAssets] = useState([]);
  const [asset, setAsset] = useState([]);
  const [data, setData] = useState({
    slug: null,
    description: "",
    ext: "",
    tags: "", // use tags input
    asset_type: "others",
    preset_code: null,
    is_public: true,
    url: "new",
  });

  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [id, setId] = useState("");
  const [deleteId, setDeleteId] = useState("");

  const rows = assets.map((item) => ({
    ...item,
    action: (
      <div>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(item.url);
            toast.success("Asset URL copied to clipboard");
          }}
        >
          Copy
        </Button>
        <Button
          onClick={() => {
            setId(item.id);
            _getAssetById(item.id);
            handleDialogOpen();
          }}
        >
          Edit
        </Button>

        <Button
          onClick={() => {
            setDeleteId(item.id);
            handleDeleteOpen();
          }}
        >
          Delete
        </Button>
      </div>
    ),
  }));

  const columns = [
    { Header: "description", accessor: "description" },
    { Header: "tags", accessor: "tags" },
    { Header: "ext", accessor: "ext" },

    { Header: "", accessor: "action", align: "right" },
  ];

  const handleDialogOpen = () => {
    setOpen((prev) => !prev);
    if (!open) {
      setId("");
    }
  };

  const _uploadAsset = async () => {
    if (content.length < 1) {
      toast.error("asset is empty");
      toast.dismiss();
      return;
    }

    toast.loading("Adding template....");
    const formData = new FormData();
    console.log({ asset });
    formData.append("file", content[0]);
    formData.append("description", data.description);
    formData.append("ext", content[0]?.name.split(".").pop());
    formData.append("asset_type", "others");
    formData.append("tags", data.tags);
    formData.append("is_public", true);
    // formData.append("preset_code", data.preset_code);
    // formData.append("slug", data.slug);

    const _template = await uploadAsset(formData);
    toast.dismiss();
    if (_template.status === 200) {
      toast.success("successfully.");
    } else {
      toast.error("Unknown error Occured", {
        position: "bottom-left",
      });
    }
  };

  const onChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleDeleteOpen = () => {
    setDeleteOpen((prev) => !prev);
  };

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      slug: data.name?.trim().split(" ").join("-"),
    }));
  }, [data.name]);

  useEffect(() => {
    getAssets();
  }, []);

  const getAssets = async () => {
    try {
      const { data } = await getAsset("others");
      setAssets(
        data.data?.content.filter(
          (item) =>
            item.ext === "webp" ||
            item.ext === "pdf" ||
            item.ext === "docs" ||
            item.ext === "xls" ||
            item.ext === "ods" ||
            item.ext === "txt" ||
            item.ext === "json"
        )
      );
      setLoading(false);
      // console.log({ data });
    } catch (err) {
      console.log(err);
    }
  };

  const _getAssetById = async (id) => {
    try {
      toast.loading("Loading template....");
      const { data } = await getAssetById(id);
      toast.dismiss();
      console.log(data.data?.asset);
      if (data.code === 200) {
        setContent(data.data?.asset.content);
        setAsset({
          url: data.data?.asset.url,
          slug: data.data?.asset.slug,
          description: data.data?.asset.description,
          ext: data.data?.asset.ext,
          tags: data.data?.asset.tags, // use tags input
          asset_type: "blog_post",
          preset_code: data.data?.asset.preset_code,
          is_public: data.data?.asset.is_public,
        });
      } else {
        toast.error("Unknown error Occured", {
          position: "bottom-left",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const _deleteAsset = async () => {
    toast.loading("Deleting Asset....");
    const _template = await deleteAsset(deleteId);
    toast.dismiss();
    handleDeleteOpen();
    getAssets();
    setDeleteId("");
    const message = _template?.data?.message || _template?.data?.data?.message;
    if (_template.status === 200) {
      getAssets();
      toast.success(message);
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox sx={{ p: 2 }}>
          <div>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" pb={3}>
              <MDTypography variant="h6" gutterBottom>
                Total Assets {assets.length}
              </MDTypography>
              <div>
                <MDButton variant="gradient" color="dark" onClick={handleDialogOpen}>
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp; Add Asset
                </MDButton>
              </div>
            </MDBox>
            {loading ? (
              <Holder />
            ) : (
              <DataTable
                className="hidden"
                table={{ columns, rows }}
                showTotalEntries={false}
                isSorted={false}
                size={100}
                noEndBorder
                entriesPerPage={false}
                style={{ display: "none" }}
                pagination={false}
              />
            )}
          </div>
          <Dialog open={open} onClose={handleDialogOpen} fullWidth>
            <DialogTitle>{id ? "Edit" : "Add"} Asset</DialogTitle>
            <DialogContent>
              {/* <DialogContentText></DialogContentText> */}

              <TextField
                autoFocus
                margin="dense"
                label="Description"
                type="text"
                fullWidth
                variant="standard"
                name="description"
                onChange={onChange}
                value={asset.description}
                // InputLabelProps={{ shrink: true }}
              />
              <TextField
                autoFocus
                margin="dense"
                label="Tags"
                type="text"
                fullWidth
                variant="standard"
                name="tags"
                onChange={onChange}
                value={asset.tags}
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                autoFocus
                margin="dense"
                label={id ? "URL" : "Slug"}
                type="text"
                fullWidth
                variant="outlined"
                onChange={onChange}
                value={id ? asset.url : asset.slug}
                name="slug"
                disabled={id}
                InputLabelProps={{ shrink: true }}
              />

              {/* <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" variant="standard">
                  Ext
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={asset.ext ?? "pdf"}
                  label="Ext"
                  onChange={onChange}
                  name="ext"
                  sx={{ mb: 2, pt: 2 }}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  disabled={id}
                >
                  {exts.map((ext) => (
                    <MenuItem value={ext.value}>{ext.label}</MenuItem>
                  ))}
                </Select>
              </FormControl> */}

              <TextField
                autoFocus
                margin="dense"
                label="Asset"
                type="file"
                fullWidth
                variant="standard"
                name="content"
                // value={content}
                onChange={(e) => setContent(e.target.files)}
                InputLabelProps={{ shrink: true }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setAsset([]);
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                // disabled={!newUser.first_name || !newUser.last_name || !newUser.email}
                onClick={_uploadAsset}
              >
                Save Asset
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={deleteOpen} onClose={handleDeleteOpen} fullWidth>
            <DialogTitle>Delete Asset</DialogTitle>

            <DialogContent>
              <DialogContentText>Are You sure you want to delete this asset</DialogContentText>{" "}
              <Typography sx={{ fontSize: "14px" }}>
                This will erase the record permanently
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteOpen}>Cancel</Button>
              <Button onClick={_deleteAsset}>Yes Delete</Button>
            </DialogActions>
          </Dialog>
        </MDBox>
      </DashboardLayout>
    </>
  );
}
