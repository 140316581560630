import axios from "axios";
import config from "../../config";

const token = localStorage.getItem("authT");
const client_id = localStorage.getItem("current_app_client_id") || "";

export const Axios = axios.create({
  baseURL: config.base_url,
});

Axios.interceptors.request.use(
  (config) => {
    let JWT;
    if (token) {
      JWT = token;
    }
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${JWT}`,
      clientId: client_id,
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      return (window.location.href = "/logout");
    }
    return Promise.reject(error);
  }
);

const responseBody = (response) => response;

const requests = {
  get: (url, body, headers) => Axios.get(url, body, headers).then(responseBody),

  post: (url, body) => Axios.post(url, body).then(responseBody),

  put: (url, body, headers) => Axios.put(url, body, headers).then(responseBody),

  patch: (url, body) => Axios.patch(url, body).then(responseBody),

  delete: (url, body, headers) => Axios.delete(url, body, headers).then(responseBody),
};

export default requests;

// eslint-disable-next-line import/prefer-default-export
export const login = async (email, password) => {
  try {
    const payload = { email, password };
    const res = await axios.post(`${config.base_url}/accounts/api/v1/auth/admin/token`, payload);
    return res;
  } catch (err) {
    if (err?.response) {
      return err?.response;
    }
    return err;
  }
};

// export const getUsers = async (page, size) => {
//   const res = await requests.get(
//     `${config.base_url}/accounts/api/v1/report/users?page=${page}&size=${size}`
//   );
//   return res;
// };

// export const postUser = async (data) => {
//   const payload = {
//     app_id: "storebounty",
//     ...data,
//   };
//   const res = await requests.post(
//     `${config.base_url}/accounts/api/v1/auth/users/register`,
//     payload
//   );
//   return res;
// };

export const getAdmins = async (page, size) => {
  const res = await requests.get(
    `${config.base_url}/accounts/api/v1/report/admins?page=${page}&size=${size}`
  );
  return res;
};
export const getBusinesses = async (page, size) => {
  const res = await requests.get(
    `${config.base_url}/accounts/api/v1/report/businesses?page=${page}&size=${size}`
  );
  return res;
};
export const getNotifications = async (page, size, type = "push") => {
  const res = await requests.get(
    `${config.base_url}/accounts/api/v1/support/notifications?msgType=${type}&page=${page}&size=${size}`
  );
  return res;
};

export const getPushNotifications = async (page, size) => {
  const res = await requests.get(
    `${config.base_url}/accounts/api/v1/support/notifications?msgType=email&page=${page}&size=${size}`
  );
  return res;
};
export const getStats = async (page, size) => {
  const res = await requests.get(
    `${config.base_url}/accounts/api/v1/report/stats?page=${page}&size=${size}`
  );
  return res;
};
export const getTickets = async (page, size) => {
  const res = await requests.get(
    `${config.base_url}/accounts/api/v1/support/tickets?page=${page}&size=${size}`,
    token
  );
  return res;
};

export const getSettings = async () => {
  const res = await requests.get(`${config.base_url}/accounts/api/v1/apps/settings`, token);
  return res;
};

export const putSettings = async (payload) => {
  const res = await requests.put(
    `${config.base_url}/accounts/api/v1/apps/settings`,
    payload,
    token
  );
  return res;
};
export const getTicket = async (id) => {
  const res = await requests.get(
    `${config.base_url}/accounts/api/v1/support/tickets/${id}/messages`
  );
  return res;
};
export const sendNotifications = async (payload) => {
  const res = await requests.post(
    `${config.base_url}/accounts/api/v1/support/notification/send`,
    payload
  );
  return res;
};
export const sendMessage = async (message, id) => {
  const payload = { message };
  const res = await requests.post(
    `${config.base_url}/accounts/api/v1/support/tickets/${id}/reply`,
    payload
  );
  return res;
};

// email templates services

export const sendEmailTest = async (payload, id) => {
  const res = await requests.post(
    `${config.base_url}/accounts/api/v1/apps/email/test?type=html`,
    payload
  );
  return res;
};

export const getEmailTemplates = async () => {
  const res = await requests.get(
    `${config.base_url}/accounts/api/v1/assets/type/email_template`,
    token
  );
  return res;
};

export const getEmailTemplatesById = async (id) => {
  const res = await requests.get(`${config.base_url}/accounts/api/v1/assets/${id}`);
  return res;
};

export const postEmailTemplates = async (payload) => {
  const res = await requests.post(`${config.base_url}/accounts/api/v1/assets`, payload);
  return res;
};

export const putEmailTemplates = async (payload) => {
  const res = await requests.put(`${config.base_url}/accounts/api/v1/assets`, payload);
  return res;
};

export const deleteEmailTemplates = async (id) => {
  const res = await requests.delete(`${config.base_url}/accounts/api/v1/assets/${id}`);
  return res;
};

// Asset services
export const getAsset = async (asset_Type) => {
  const res = await requests.get(`${config.base_url}/accounts/api/v1/assets/type/${asset_Type}`);
  return res;
};

export const getAssetById = async (id) => {
  const res = await requests.get(`${config.base_url}/accounts/api/v1/assets/${id}`);
  return res;
};

export const postAsset = async (payload) => {
  const res = await requests.post(`${config.base_url}/accounts/api/v1/assets`, payload);
  return res;
};

export const putAsset = async (payload) => {
  const res = await requests.put(`${config.base_url}/accounts/api/v1/assets`, payload);
  return res;
};

export const deleteAsset = async (id) => {
  const res = await requests.delete(`${config.base_url}/accounts/api/v1/assets/${id}`);
  return res;
};

export const uploadAsset = async (payload) => {
  const res = await requests.post(`${config.base_url}/accounts/api/v1/assets/upload`, payload);
  return res;
};

export const updateUpload = async (payload) => {
  const res = await requests.put(`${config.base_url}/accounts/api/v1/assets/upload`, payload);
  return res;
};

export const switchApps = async (clientId) => {
  const payload = { client_id: clientId };
  const res = await requests.post(
    `${config.base_url}/accounts/api/v1/auth/admin/token/exchange`,
    payload
  );
  return res;
};
