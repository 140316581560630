import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

export default function DeleteModal({ openDelete, closeDelete, deleteAsset, title }) {
  return (
    <Dialog open={openDelete} onClose={closeDelete} fullWidth>
      <DialogTitle>Delete Asset</DialogTitle>

      <DialogContent>
        <DialogContentText>Are You sure you want to delete this ${title}</DialogContentText>{" "}
        <Typography sx={{ fontSize: "14px" }}>This will erase this record permanently</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDelete}>Cancel</Button>
        <Button onClick={deleteAsset}>Yes Delete</Button>
      </DialogActions>
    </Dialog>
  );
}
