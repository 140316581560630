import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AssetServices from "services/assetServices";

export default function useAssetSubmit({ id, asset_Type, getAssets = () => {} }) {
  const [asset, setAsset] = useState({
    slug: "",
    description: "",
    ext: "html",
    tags: "",
    asset_type: asset_Type,
    preset_code: null,
    is_public: true,
    app_id: "storebounty",
  });
  const [content, setContent] = useState("");

  useEffect(() => {
    setAsset((prev) => ({
      ...prev,
      slug: asset.name?.replace(/\W+/g, "-").toLowerCase(),
      preset_code: asset.name?.replace(/\W+/g, "-").toLowerCase(),
    }));
  }, [asset.name]);

  const onChange = (e) => {
    setAsset((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onsubmit = (e) => {
    if (id) {
      updateAsset();
    } else {
      addAsset();
    }
  };

  const addAsset = async () => {
    if (!asset.description) {
      toast.error("description is required");
      return;
    }
    if (!asset.preset_code) {
      toast.error("preset code is required");
      return;
    }
    if (!content) {
      toast.error("content is required");
      return;
    }
    toast.loading("Adding template....");
    const _template = await AssetServices.postAsset({ ...asset, content });
    toast.dismiss();
    const message = _template?.data?.message || _template?.data?.data?.message;

    if (_template.status === 200) {
      getAssets();
      toast.success(message);
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  const updateAsset = async () => {
    toast.loading("Adding template....");
    const _template = await AssetServices.putAsset({ ...asset, content, id });
    toast.dismiss();
    const message = _template?.data?.message || _template?.data?.data?.message;
    if (_template.status === 200) {
      getAssets();
      toast.success(message);
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  useEffect(() => {
    if (id) {
      const _getAssetById = async (id) => {
        try {
          toast.loading("Loading....");

          const { data } = await AssetServices.getAssetById(id);
          toast.dismiss();
          if (data.code === 200) {
            setContent(data.data?.asset.content);
            setAsset({
              description: data.data?.asset.description,
              ext: data.data?.asset.ext,
              tags: data.data?.asset.tags, // use tags input
              asset_type: data.data?.asset.asset_type,
              preset_code: data.data?.asset.preset_code,
              is_public: data.data?.asset.is_public,
            });
          } else {
            toast.error("Unknown error Occured", {
              position: "bottom-left",
            });
          }
        } catch (err) {
          console.log(err);
        }
      };

      _getAssetById(id);
    }
  }, []);

  return {
    asset,
    setAsset,
    content,
    setContent,
    onsubmit,
    onChange,
  };
}
