/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// custom styles for the NotificationItem
import menuItem from "examples/Items/NotificationItem/styles";
import Avatar from "@mui/material/Avatar";
import NotificationIcon from "assets/svgs/notification-svg.svg";
import moment from "moment";
const NotificationItem = forwardRef(({ notification, ...rest }, ref) => (
  <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
    <Link to={`/ticket/${notification?.id}`}>
      <MDBox py={0.5} display="flex" alignItems="center" lineHeight={1} sx={{ width: "100%" }}>
        <Avatar alt="" src={NotificationIcon} sx={{ width: 24, height: 24 }} />
        <MDBox sx={{ display: "flex", flexDirection: "column", flexGrow: "1", width: "100%" }}>
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap="10px"
            sx={{ width: "100%", flexGrow: "1" }}
          >
            <div>
              <MDTypography variant="button" fontWeight="regular" sx={{ ml: 1 }}>
                {notification?.title.length > 30
                  ? notification?.title.substring(0, 30) + "..."
                  : notification?.title}
              </MDTypography>
            </div>
            <div>
              <MDTypography variant="button" fontWeight="light">
                {notification?.date && moment(notification?.date).format("L")}
              </MDTypography>
            </div>
          </MDBox>
          <MDTypography variant="button" fontWeight="light" sx={{ ml: 1 }}>
            {notification?.body?.length > 30
              ? notification?.body.substring(0, 30) + "..."
              : notification?.body}
          </MDTypography>
        </MDBox>
        <ChevronRightIcon />
      </MDBox>
    </Link>
  </MenuItem>
));

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default NotificationItem;
