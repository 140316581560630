import requests from "components/api";

const PermissionServices = {
  async getPermissions() {
    const res = await requests(`/accounts/api/v1/apps/permissions`);
    return res;
  },

  async getPermissionsById(id) {
    const res = await requests(`/accounts/api/v1/apps/permissions/${id}`);
    return res;
  },

  async addPermission(payload) {
    const res = await requests(`/accounts/api/v1/apps/permissions`, payload);
    return res;
  },

  async editPermission(payload) {
    const res = await requests(`/accounts/api/v1/apps/permissions`, payload);
    return res;
  },

  async deletePermission(id) {
    const res = await requests(`/accounts/api/v1/apps/permissions/${id}`);
    return res;
  },
};

export default PermissionServices;
