import { useEffect, useState } from "react";

export default function useDebounce(search, duration = 500) {
  const [debouncedValue, setDebouncedValue] = useState();

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(search);
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [search, duration]);

  return { debouncedValue };
}
