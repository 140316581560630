import DataTable from "examples/Tables/DataTable";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import { Icon } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PermissionServices from "services/PermissionServices";
import "../pagination.css";
import Holder from "../tableholder";
import { Typography } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";

function Permissions() {
  const columns = [
    { Header: "Permission Name", accessor: "name" },
    { Header: "Routes", accessor: "routes" },
    { Header: "", accessor: "action", align: "right" },
  ];

  const [Permission, setPermission] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [id, setId] = useState();

  const [data, setData] = useState({
    name: "",
    routes: "",
  });
  const rows = Permission.map((item) => ({
    ...item,
    action: (
      <div>
        <Button
          onClick={() => {
            setId(item.id);
            fetchPermissionsById(item.id);
            handleDialogOpen();
          }}
        >
          Edit
        </Button>
        <Button
          onClick={() => {
            setId(item.id);
            handleDeleteOpen();
          }}
        >
          Delete
        </Button>
      </div>
    ),
  }));
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPermissions(page, size);
  }, []);

  const onChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleDialogOpen = () => {
    setOpen((prev) => !prev);
    if (id) {
      setData({
        name: "",
        description: "",
        routes: "",
      });
      setId("");
    }
  };

  const handleDeleteOpen = () => {
    setDeleteOpen((prev) => !prev);
    if (id) {
      setId("");
    }
  };

  const fetchPermissions = async () => {
    setLoading(true);
    const PermissionList = await PermissionServices.getPermissions();
    setLoading(false);
    console.log({ PermissionList });

    if (PermissionList.status === 200) {
      setPermission(PermissionList.data?.data?.permissions);
    } else {
      toast.error("Unable to fetch Permissions", {
        position: "bottom-left",
      });
    }
  };

  const fetchPermissionsById = async (id) => {
    setLoading(true);
    const PermissionList = await PermissionServices.getPermissionsById(id);
    setLoading(false);
    console.log({ PermissionList });

    if (PermissionList.status === 200) {
      const { description, name, routes } = PermissionList.data?.data?.permission;
      setData({
        name,
        description,
        routes,
      });
    } else {
      toast.error("Unable to fetch Permissions", {
        position: "bottom-left",
      });
    }
  };

  const updatePermissions = async () => {
    setLoading(true);
    const PermissionList = await PermissionServices.editPermission({ id, ...data });
    setLoading(false);

    const message = Permission?.data?.message || Permission?.data?.data?.message;
    if (PermissionList.status === 200) {
      toast.success(message);
      handleDialogOpen();
      setData({
        name: "",
        description: "",
        routes: "",
      });
      fetchPermissions();
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  const addPermissions = async () => {
    toast.loading("Creating New Permission....");
    const Permission = await PermissionServices.addPermission(data);
    // console.log({ Permission });
    toast.dismiss();
    const message = Permission?.data?.message || Permission?.data?.data?.message;
    if (Permission.status === 200) {
      toast.success(message);
      handleDialogOpen();
      setData({
        name: "",
        description: "",
        routes: "",
      });
      fetchPermissions();
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  const deletePermissions = async () => {
    toast.loading("Deleting Permission....");
    const Permission = await PermissionServices.deletePermission(id);
    // console.log({ Permission });
    toast.dismiss();
    const message = Permission?.data?.message || Permission?.data?.data?.message;
    if (Permission.status === 200) {
      toast.success(message);
      handleDeleteOpen();
      fetchPermissions();
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDBox>
          <div style={{ display: "block" }}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDTypography variant="h6" gutterBottom>
                  Permissions
                </MDTypography>
                <div>
                  <MDButton variant="gradient" color="dark" onClick={handleDialogOpen}>
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp; create Permission
                  </MDButton>
                </div>
              </MDBox>
              {loading ? (
                <Holder />
              ) : (
                <DataTable
                  className="hidden "
                  table={{ columns, rows }}
                  showTotalEntries={false}
                  isSorted={false}
                  size={size}
                  entriesPerPage={false}
                  pagination={false}
                />
              )}
            </Card>
          </div>
        </MDBox>
      </MDBox>

      <Dialog open={open} onClose={handleDialogOpen} fullWidth>
        <DialogTitle>{id ? "Edit" : "Create"} Permission</DialogTitle>
        <DialogContent>
          {/* <DialogContentText></DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="notification"
            label="Permission Name"
            type="text"
            fullWidth
            variant="standard"
            name="name"
            value={data.name}
            onChange={onChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="notification"
            label="Permission Routes"
            type="text"
            fullWidth
            variant="standard"
            rows={3}
            multiline
            name="routes"
            value={data.routes}
            onChange={onChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button disabled={data.name === ""} onClick={id ? updatePermissions : addPermissions}>
            Save {id ? "changes" : "Permission"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteOpen} onClose={handleDeleteOpen} fullWidth>
        <DialogTitle>Delete Permission</DialogTitle>

        <DialogContent>
          <DialogContentText>Are You sure you want to delete this permission</DialogContentText>{" "}
          <Typography sx={{ fontSize: "14px" }}>This will erase the record permanently</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteOpen}>Cancel</Button>
          <Button onClick={deletePermissions}>Yes Delete</Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Permissions;
