import UIContext from "context/UIContext";
import { useContext, useEffect, useState } from "react";
import useDebounce from "./useDebounce";

export default function useAsync(asyncFn, { params, page, size, search, setPageCount, setTotal }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setIsUpdated, isUpdated } = useContext(UIContext);
  const { debouncedValue } = useDebounce(search);

  useEffect(() => {
    try {
      const getData = async () => {
        const res = await asyncFn(params, { page, size, search: debouncedValue });
        setData(res);
        setPageCount(res.data.data.pages || res.data.data.total_pages);
        setTotal(res.data.data.total || res.data.data.total_element);
      };
      getData();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      if (isUpdated) {
        setIsUpdated(false);
      }
    }

    //eslint-disable-next-line
  }, [params, asyncFn, page, debouncedValue, size, isUpdated]);

  return {
    loading,
    data,
    setIsUpdated,
  };
}
