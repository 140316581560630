import requests from "components/api";

const EmailServices = {
  async sendEmailTest(payload, id) {
    const res = await requests.post(`/accounts/api/v1/apps/email/test?type=html`, payload);
    return res;
  },

  async getEmailTemplates(_, { page, size, search = "" }) {
    const res = await requests.get(
      `/accounts/api/v1/assets/type/email_template?page=${page}&size=${size}&q=${search}`
    );
    return res;
  },

  async getEmailTemplatesById(id) {
    const res = await requests.get(`/accounts/api/v1/assets/${id}`);
    return res;
  },

  async postEmailTemplates(payload) {
    const res = await requests.post(`/accounts/api/v1/assets`, payload);
    return res;
  },

  async putEmailTemplates(payload) {
    const res = await requests.put(`/accounts/api/v1/assets`, payload);
    return res;
  },

  async deleteEmailTemplates(id) {
    const res = await requests.delete(`/accounts/api/v1/assets/${id}`);
    return res;
  },
};

export default EmailServices;
