import { javascript } from "@codemirror/lang-javascript";
import { Card, Icon, TextField } from "@mui/material";
import { okaidia } from "@uiw/codemirror-theme-okaidia";
import CodeMirror from "@uiw/react-codemirror";
import { getAssetById, postAsset, putAsset } from "components/api";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function CSSAddEdit() {
  const query = new URLSearchParams(window.location.search);
  const id = query.get("id");
  const [asset, setAsset] = useState({
    slug: "",
    description: "",
    ext: "css",
    tags: "", // use tags input
    asset_type: "others",
    preset_code: "",
    is_public: true,

    url: "",
  });
  const [content, setContent] = useState("");

  useEffect(() => {
    if (id) {
      _getAssetById(id);
    }
  }, []);

  const onChange = React.useCallback((value, viewUpdate) => {
    setContent(value);
  }, []);

  const inputChange = (e) => {
    setAsset((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const _getAssetById = async (id) => {
    try {
      toast.loading("Loading template....");
      const { data } = await getAssetById(id);
      toast.dismiss();
      console.log(data.data?.asset);
      if (data.code === 200) {
        setContent(data.data?.asset.content);
        setAsset({
          url: data.data?.asset.url,
          slug: data.data?.asset.slug,
          description: data.data?.asset.description,
          ext: data.data?.asset.ext,
          tags: data.data?.asset.tags, // use tags input
          asset_type: "others",
          preset_code: data.data?.asset.preset_code,
          is_public: data.data?.asset.is_public,
        });
      } else {
        toast.error("Unknown error Occured", {
          position: "bottom-left",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addAsset = async () => {
    toast.loading("Adding CSS....");
    const _template = await postAsset({ ...asset, content });
    toast.dismiss();
    const message = _template?.data?.message || _template?.data?.data?.message;

    if (_template.status === 200) {
      toast.success(message);
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  const updateAsset = async () => {
    toast.loading("Adding template....");
    const _template = await putAsset({ ...asset, content, id });
    toast.dismiss();
    if (_template.status === 200) {
      toast.success("successfully.");
    } else {
      toast.error("Unknown error Occured", {
        position: "bottom-left",
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox sx={{ p: 2 }}>
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <MDTypography variant="h6" gutterBottom>
              {id ? "Update" : "Create"} CSS
            </MDTypography>
            <div>
              <MDButton
                variant="gradient"
                color="dark"
                onClick={id ? updateAsset : addAsset}
                // disabled={!asset.name || !asset.content}
              >
                <Icon sx={{ fontWeight: "bold" }}>save</Icon>
                &nbsp; {id ? "Update" : "Save"} CSS
              </MDButton>
            </div>
          </MDBox>
          <MDBox sx={{ px: 3 }}>
            <TextField
              autoFocus
              margin="dense"
              id="notification"
              label="CSS Description"
              type="text"
              fullWidth
              variant="outlined"
              onChange={inputChange}
              value={asset.description}
              name="description"
            />
            <TextField
              autoFocus
              margin="dense"
              id="notification"
              label="CSS Tags"
              type="text"
              fullWidth
              variant="outlined"
              onChange={inputChange}
              value={asset.tags}
              name="tags"
            />
            <TextField
              autoFocus
              margin="dense"
              id="notification"
              label={id ? "URL" : "Slug"}
              type="text"
              fullWidth
              variant="outlined"
              onChange={inputChange}
              value={id ? asset.url : asset.slug}
              name="slug"
              disabled={id}
            />
            <CodeMirror
              value={content}
              height="600px"
              theme={okaidia}
              extensions={[javascript({ jsx: true })]}
              onChange={onChange}
              style={{
                fontSize: "16px",
                paddingTop: "15px",
              }}
            />
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
