import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

export default function AttributeModal({
  open,
  setOpen,
  attribute,
  handleAttributeChange,
  handleSubmit,
  setAttributes,
}) {
  const handleClose = () => {
    setAttributes(null);
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Attributes</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="notification"
          label={"key"}
          type="text"
          fullWidth
          variant="standard"
          name={"key"}
          value={attribute?.key}
          onChange={handleAttributeChange}
        />
        <TextField
          autoFocus
          margin="dense"
          id="notification"
          label={"value"}
          type="text"
          fullWidth
          variant="standard"
          name={"value"}
          value={attribute?.value}
          onChange={handleAttributeChange}
        />
        <br />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Save Attributes</Button>
      </DialogActions>
    </Dialog>
  );
}
