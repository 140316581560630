import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import EmailCreateEdit from "layouts/emailing/EmailCreateEdit";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Groups from "./group/Groups";
import Grid from "@mui/material/Grid";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box>
        <Grid container spacing={{ xs: 12 }} columns={{ xs: 12 }}>
          <Grid item xs={2}>
            <Box sx={{ mt: 3 }}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{
                  borderRight: 1,
                  borderColor: "divider",
                  height: "100%",
                  minWidth: "200px",
                  justifyContent: "start",
                  // display: "flex",
                }}
              >
                <Tab label="Groups" {...a11yProps(0)} />
                <Tab label="Roles" {...a11yProps(1)} />
                <Tab label="Basic Info" {...a11yProps(2)} />
                <Tab label="App Settings" {...a11yProps(3)} />
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={10}>
            <TabPanel value={value} index={0}>
              <Groups />
            </TabPanel>
            <TabPanel value={value} index={1}>
              Groups
            </TabPanel>
            <TabPanel value={value} index={2}>
              Roles
            </TabPanel>
            <TabPanel value={value} index={3}>
              Basic Info
            </TabPanel>
            <TabPanel value={value} index={4}>
              App Settings
            </TabPanel>
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
}
