import React from "react";
import ContentLoader from "react-content-loader";

const CleanChat = (props) => {
  return (
    <ContentLoader
      viewBox="0 0 400 160"
      height={500}
      backgroundColor="#f5f5f5"
      foregroundColor="#dbdbdb"
      {...props}
    >
      <rect x="0" y="12" rx="5" ry="5" width="220" height="10" />
      <rect x="0" y="29" rx="5" ry="5" width="220" height="10" />
      <rect x="179" y="76" rx="5" ry="5" width="220" height="10" />
      <rect x="179" y="58" rx="5" ry="5" width="220" height="10" />
      <rect x="0" y="104" rx="5" ry="5" width="220" height="10" />
      <rect x="0" y="122" rx="5" ry="5" width="220" height="10" />

      <rect x="179" y="140" rx="5" ry="5" width="220" height="10" />
      <rect x="179" y="155" rx="5" ry="5" width="220" height="10" />
    </ContentLoader>
  );
};

CleanChat.metadata = {
  name: "Pablo Maribondo",
  github: "pablomaribondo",
  description: "Clean Chat",
  filename: "CleanChat",
};

export default CleanChat;
