import { Icon } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import FilterByDate from "components/forms/FilterByDate";
import SelectQuickActions from "components/forms/SelectQuickActions";
import SelectUserFilter from "components/forms/SelectUserFilter";
import LongMenu from "components/table/Menu";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable/DataTableRowSelect";
import useAsync from "hooks/useAsync";
import moment from "moment";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import UserServices from "services/UserServices";
import "./pagination.css";
import Holder from "./tableholder";

function Users() {
  const [open, setOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
  });

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState();
  const { data, loading } = useAsync(UserServices.getUsers, {
    page,
    size,
    search,
    setPageCount,
    setTotal,
  });

  const rows = [...(data?.data?.data?.users || [])];
  const columns = [
    {
      Header: "Name",
      accessor: "first_name",
      align: "left",
      Cell: ({ row }) => {
        const { original } = row;
        const res = original.first_name && (
          <span>
            {original.first_name} {original.last_name}
          </span>
        );
        return res;
      },
    },
    {
      Header: "Email Address",
      align: "left",
      accessor: "email",
      Cell: ({ row }) => {
        const { original } = row;
        const res = original.email ? (
          <>
            <span>{original.email}</span>{" "}
            {original.email_verified ? (
              <i className="fa fa-check" title="Email has been verified"></i>
            ) : (
              ""
            )}
          </>
        ) : (
          "N/A"
        );
        return res;
      },
    },
    {
      Header: "Phone",
      align: "left",
      accessor: "phone",
      Cell: ({ row }) => {
        const { original } = row;

        const res = original.phone ? (
          <>
            <span>{original.phone}</span>{" "}
            {original.phone_verified ? (
              <i className="fa fa-check" title="Email has been verified"></i>
            ) : (
              ""
            )}
          </>
        ) : (
          "N/A"
        );
        return res;
      },
    },
    {
      Header: "Date created",
      accessor: "createdAt",
      Cell: ({ value }) => {
        return <span>{moment(value).format("L")}</span>;
      },
    },
    {
      Header: "Last Seen",
      accessor: "lastSeen",
      filterable: true,
      Cell: ({ value }) => {
        return <span>{moment(value).format("L")}</span>;
      },
    },
    { Header: "Status", accessor: "status", align: "center" },
    {
      Header: "",
      accessor: "action",
      align: "right",
      Cell: ({ row }) => {
        const { original } = row;
        const res = <LongMenu data={original} />;
        return res;
      },
    },
  ];

  const handlePageClick = async (e) => {
    const newpage = e.selected;
    setPage(newpage);
  };

  const addUsers = async () => {
    toast.loading("Adding User....");
    const _newUser = await UserServices.postUser(newUser);
    toast.dismiss();
    if (_newUser.status === 200) {
      toast.success("successfully.");
    } else {
      toast.error("Unknown error Occured", {
        position: "bottom-left",
      });
    }
  };

  const onChange = (e) => {
    console.log({ newUser });
    setNewUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const changeSize = (pagesize) => {
    if (pagesize == "") {
      return;
    }
    setSize(pagesize);
    // fetchUsers(page, pagesize);
  };

  const handleDialogOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />

        <MDBox py={3}>
          <MDBox>
            <div style={{ display: "block" }}>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" pb={3}>
                <MDTypography variant="h6" gutterBottom>
                  Users
                </MDTypography>
                <div>
                  <MDButton variant="gradient" color="dark" onClick={handleDialogOpen}>
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp; create user
                  </MDButton>
                </div>
              </MDBox>
              <Card>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ gap: 1, p: 3 }}
                >
                  <TextField
                    label="Search input"
                    type="search"
                    fullWidth
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <SelectUserFilter />
                  <FilterByDate />
                  <SelectQuickActions />
                </MDBox>
                {loading ? (
                  <Holder />
                ) : (
                  <MDBox sx={{ position: "relative" }}>
                    <DataTable
                      className="hidden"
                      table={{ columns, rows }}
                      showTotalEntries={false}
                      isSorted={false}
                      size={size}
                      noEndBorder
                      entriesPerPage={false}
                      style={{ display: "none" }}
                      pagination={false}
                    />
                  </MDBox>
                )}
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <MDTypography variant="h6" gutterBottom>
                    <ReactPaginate
                      pageRangeDisplayed={3}
                      breakLabel="..."
                      nextLabel={<i className="fa fa-angle-double-right"></i>}
                      onPageChange={handlePageClick}
                      pageCount={pageCount}
                      previousLabel={<i className="fa fa-angle-double-left"></i>}
                      containerClassName="pagination"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      activeClassName="active"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      renderOnZeroPageCount={false}
                    />
                  </MDTypography>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    lineHeight={0}
                    justifyContent="space-between"
                  >
                    <div>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;Showing <strong>{data.length} </strong> users of {total}
                      </MDTypography>
                    </div>
                    <div>
                      <select
                        className="select-size"
                        onChange={(e) => changeSize(e.target.value)}
                        value={size}
                      >
                        <option value="">Page Size</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </MDBox>
                </MDBox>
              </Card>
            </div>
          </MDBox>
        </MDBox>
      </DashboardLayout>

      <Dialog open={open} onClose={handleDialogOpen} fullWidth>
        <DialogTitle>Create User</DialogTitle>
        <DialogContent>
          {/* <DialogContentText></DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="notification"
            label="First Name"
            type="text"
            fullWidth
            variant="standard"
            name="first_name"
            value={newUser.first_name}
            onChange={onChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="notification"
            label="Last Name"
            type="text"
            fullWidth
            variant="standard"
            name="last_name"
            value={newUser.last_name}
            onChange={onChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="notification"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            name="email"
            value={newUser.email}
            onChange={onChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="notification"
            label="Phone Number"
            type="number"
            fullWidth
            variant="standard"
            name="phone"
            value={newUser.phone}
            onChange={onChange}
          />

          <br />
          <MDBox display="flex" gap="2" alignItems="center">
            <MDTypography variant="button" fontWeight="regular" color="text">
              Active
            </MDTypography>
            <Switch />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            // disabled={!newUser.first_name || !newUser.last_name || !newUser.email}
            onClick={addUsers}
          >
            Save User
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Users;
