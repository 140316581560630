import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import AttributeModal from "examples/modals/AttributeModal";
import DeleteModal from "examples/modals/DeleteModal";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useAsync from "hooks/useAsync";
import useAttributeSubmit from "hooks/useAttributeSubmit";
import UserServices from "services/UserServices";

export default function Detail() {
  const query = new URLSearchParams(window.location.search);
  const id = query.get("id");
  const { data, loading } = useAsync(UserServices.getById, { params: id });
  const {
    attribute,
    setAttributes,
    handleAttributeChange,
    open,
    setOpen,
    handleSubmit,
    openDelete,
    setOpenDelete,
    deleteAsset,
    closeDelete,
  } = useAttributeSubmit({
    id,
  });

  return (
    <div>
      <AttributeModal
        open={open}
        setOpen={setOpen}
        attribute={attribute}
        setAttributes={setAttributes}
        handleAttributeChange={handleAttributeChange}
        handleSubmit={handleSubmit}
      />
      <DeleteModal
        openDelete={openDelete}
        closeDelete={closeDelete}
        deleteAsset={deleteAsset}
        title={"Attribute"}
      />
      <DashboardLayout>
        <DashboardNavbar />
        {data && (
          <div>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
              <Typography>{data?.data?.data?.info?.email}</Typography>
              <MDButton onClick={() => setOpen(true)}>Add Attribute</MDButton>
            </MDBox>

            <TableContainer component={Paper}>
              <table aria-label="table" width={"100%"}>
                <thead>
                  <TableRow>
                    <TableCell>Key</TableCell>
                    <TableCell align="right">Value</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </thead>
                <TableBody>
                  {data?.data?.data?.info?.attributes?.map((row) => (
                    <TableRow
                      key={row.key}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" sx={{ fontSize: 16 }}>
                        {row.key}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: 16 }}>
                        {row.value}
                      </TableCell>
                      <TableCell align="right">
                        <div>
                          <Button
                            onClick={() => {
                              setAttributes(row);
                              setOpen(true);
                            }}
                          >
                            <EditIcon sx={{ color: "gray" }} />
                          </Button>

                          <Button
                            onClick={() => {
                              setAttributes(row);
                              setOpenDelete(true);
                            }}
                          >
                            <DeleteIcon sx={{ color: "red" }} />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </table>
            </TableContainer>
          </div>
        )}
      </DashboardLayout>
    </div>
  );
}
