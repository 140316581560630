import { sendNotifications } from "components/api";
import { useState } from "react";
import { toast } from "react-toastify";

export default function useEmailTest(callback) {
  const [tstOpen, setTstOpen] = useState(false);
  const [tokens, setTokens] = useState([]);

  const handleTstOpen = (id) => {
    setTstOpen((prev) => !prev);
  };

  const [tstData, setTstData] = useState({
    to: "",
    recipients: "",
    message: "",
    tokens: null,
    salutation: null,
    preset_code: null,
  });

  const serializeRecipients = (recipients) => {
    return recipients.split(",");
  };

  const onSubmit = async () => {
    if (!tstData.subject) {
      toast.error("Subject is required");
      return;
    }
    if (!tstData.message) {
      toast.error("Message is required");
      return;
    }

    toast.loading("sending template...");
    const payload = {
      recipients: tstData.recipients,
      title: tstData.subject,
      body: tstData.message,
      tokens: tstData.tokens,
      msg_type: "email",
    };

    // console.log(payload);
    const res = await sendNotifications(payload);
    toast.dismiss();
    const message = res?.data?.message || res?.data?.data?.message;
    if (res.status === 200) {
      toast.success(message);
      setTstOpen(false);
      setTstData({
        to: "",
        recipients: "",
        message: "",
        tokens: null,
        salutation: null,
      });

      if (callback) {
        callback();
      }
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  return {
    tstOpen,
    setTstOpen,
    onSubmit,
    handleTstOpen,
    tstData,
    setTstData,
    tokens,
    setTokens,
  };
}
