import { Card, Icon, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { getEmailTemplatesById } from "components/api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useAsync from "hooks/useAsync";
import useEmailTest from "hooks/useEmailTest";
import JoditEditor from "jodit-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import EmailServices from "services/EmailServices";
export default function SendEmail({ placeholder }) {
  // Fetch Emails
  const navigate = useNavigate();
  const { data, loading } = useAsync(EmailServices.getEmailTemplates, {
    page: 0,
    size: 50,
  });

  const [selectedTemplate, setSeletedTemplate] = useState(null);
  const { tokens, setTokens, tstData, setTstData, tstOpen, setTstOpen, onSubmit } = useEmailTest(
    () => navigate("/notifications/push")
  );

  // TODO: organize
  const handleTstChange = (e) => {
    setTstData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleContentChange = (value) => {
    setTstData((prev) => ({ ...prev, message: value }));
  };

  const handleTokenChange = (e) => {
    setTstData((prev) => ({
      ...prev,
      tokens: { ...prev.tokens, [e.target.name]: e.target.value },
    }));
  };
  //   =====

  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/,
      placeholder: placeholder || "Start typings...",
      height: 600,
    }),
    [placeholder]
  );

  useEffect(() => {
    if (!selectedTemplate) return;
    getTemplateById(selectedTemplate?.id);
  }, [selectedTemplate]);

  useEffect(() => {
    const location = window.location.search;
    const query = new URLSearchParams(location);
    const email = query.get("email");
    if (email) {
      setTstData((prev) => ({ ...prev, recipients: email, recipients_type: "users" }));
    }
  }, []);

  const getTemplateById = async (id) => {
    try {
      toast.loading("Loading template....");
      const { data } = await getEmailTemplatesById(id);
      toast.dismiss();
      console.log(data.data?.asset);
      if (data.code === 200) {
        handleContentChange(data.data?.asset.content);
        setTokens(data.data?.asset?.tokens);
      } else {
        toast.error("Unknown error Occured", {
          position: "bottom-left",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <div style={{ display: "block" }}>
              <Card>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <MDTypography variant="h6" gutterBottom>
                    Send Email
                  </MDTypography>
                  <div>
                    <MDButton variant="gradient" color="dark" onClick={onSubmit}>
                      <Icon sx={{ fontWeight: "bold" }}>mail</Icon>
                      &nbsp; Send Mail
                    </MDButton>
                  </div>
                </MDBox>
                <MDBox px={8} pb={10}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-standard-label">
                      Template{" "}
                      {selectedTemplate?.description ? `(${selectedTemplate?.description})` : ""}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={selectedTemplate?.description || ""}
                      onChange={(e) => {
                        setSeletedTemplate(e.target.value);
                      }}
                      label="Template"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {data.data?.data?.content
                        ?.sort((a, b) => a.deletable - b.deletable)
                        ?.map((item) => (
                          <MenuItem data-info={item} value={item} key={item.id}>
                            <em>{item.description}</em>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <FormControl variant="standard" fullWidth margin="dense">
                    <InputLabel id="demo-simple-select-standard-label">Recipient Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      name="recipients_type"
                      value={tstData?.recipients_type || ""}
                      defaultValue={"all"}
                      onChange={handleTstChange}
                      label="Select Recipient"
                    >
                      <MenuItem value="all">
                        <em>All</em>
                      </MenuItem>
                      <MenuItem value="users">
                        <em>Select Users</em>
                      </MenuItem>
                    </Select>
                  </FormControl>

                  {tstData.recipients_type === "users" ? (
                    <TextField
                      fullWidth
                      margin="dense"
                      label="Recipients (:to)"
                      name="recipients"
                      variant="standard"
                      value={tstData.recipients}
                      onChange={handleTstChange}
                    />
                  ) : null}

                  {selectedTemplate?.tokens?.split(",").map((token) => (
                    <TextField
                      fullWidth
                      label={token}
                      name={token}
                      value={tokens?.token}
                      margin="dense"
                      variant="standard"
                      onChange={handleTokenChange}
                    />
                  ))}

                  <TextField
                    margin="dense"
                    fullWidth
                    label="Subject"
                    name="subject"
                    variant="standard"
                    value={tstData.subject}
                    onChange={handleTstChange}
                  />

                  <br />

                  <MDTypography variant="button" fontWeight="regular" color="text">
                    Template
                  </MDTypography>

                  <JoditEditor
                    ref={editor}
                    config={config}
                    tabIndex={1}
                    value={tstData.message}
                    // onChange={handleTstChange}
                    onBlur={(newContent) => handleContentChange(newContent)}
                    onChange={(newContent) => {}}
                  />
                </MDBox>
              </Card>
            </div>
          </MDBox>
        </MDBox>
      </DashboardLayout>
    </div>
  );
}
