import { Icon, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useCallback, useEffect, useState } from "react";
// import Switch from "@mui/material/Switch";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import { deleteAsset, updateUpload, uploadAsset } from "components/api";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

export default function Images({ images, getAssets }) {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState([]);
  const [data, setData] = useState({
    slug: "",
    description: "",
    ext: "",
    tags: "",
    asset_type: "media",
    preset_code: "",
    is_public: true,
  });
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [serviceId, setServiceId] = useState("");

  const handleDeleteOpen = () => {
    setDeleteOpen((prev) => !prev);
  };

  const handleDialogOpen = (item) => {
    console.log({ item });
    if (serviceId) {
      setData({
        slug: item.slug,
        description: item.description,
        ext: item.ext,
        tags: item.tags,
        asset_type: "media",
        preset_code: item.preset_code,
        is_public: true,
        id: item.id,
      });
    } else {
      setData({
        slug: "",
        description: "",
        ext: "",
        tags: "",
        asset_type: "media",
        preset_code: "",
        is_public: true,
      });
    }
    setOpen((prev) => !prev);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setImage(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
  });

  const _uploadAsset = async () => {
    if (image.length < 1) {
      toast.error("Image is empty");
      toast.dismiss();
      return;
    }

    if (!data.slug) {
      toast.error("Slug is required");
      toast.dismiss();
      return;
    }

    toast.loading("Adding template....");
    const formData = new FormData();
    formData.append("file", image[0]);
    formData.append("description", data.description);
    formData.append("ext", image[0]?.name.split(".")[1]);
    formData.append("asset_type", "media");
    formData.append("tags", data.tags);
    formData.append("is_public", true);
    formData.append("preset_code", data.preset_code);
    formData.append("slug", data.slug);

    const _template = await uploadAsset(formData);
    toast.dismiss();
    // console.log(_template);
    const message = _template?.data?.message || _template?.data?.data?.message;

    if (_template.status === 200) {
      handleDialogOpen();
      getAssets();
      toast.success(message);
      setData({
        slug: "",
        description: "",
        ext: "",
        tags: "",
        asset_type: "media",
        preset_code: "",
        is_public: true,
      });
      setImage([]);
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  const _updateAsset = async () => {
    if (image.length < 1) {
      toast.error("Image is empty");
      toast.dismiss();
      return;
    }

    if (!data.slug) {
      toast.error("Slug is required");
      toast.dismiss();
      return;
    }

    toast.loading("Updating Asset....");
    const formData = new FormData();
    formData.append("file", image[0]);
    formData.append("description", data.description);
    formData.append("ext", image[0]?.name.split(".")[1]);
    formData.append("asset_type", "media");
    formData.append("tags", data.tags);
    formData.append("is_public", true);
    formData.append("preset_code", data.preset_code);
    formData.append("slug", data.slug);

    formData.append("id", serviceId);
    const _template = await updateUpload(formData);
    toast.dismiss();
    // console.log(_template);
    const message = _template?.data?.message || _template?.data?.data?.message;

    if (_template.status === 200) {
      handleDialogOpen();
      getAssets();
      toast.success(message);
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  const _deleteAsset = async () => {
    toast.loading("Deleting template....");
    const _template = await deleteAsset(deleteId);
    toast.dismiss();
    handleDeleteOpen();
    getAssets();
    setDeleteId("");
    const message = _template?.data?.message || _template?.data?.data?.message;
    if (_template.status === 200) {
      getAssets();
      toast.success(message);
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  const onChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      slug: data.name?.trim().split(" ").join("-"),
    }));
  }, [data.name]);

  useEffect(() => {
    getAssets();
  }, []);

  return (
    <>
      <MDBox>
        <div>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" pb={3}>
            <MDTypography variant="h6" gutterBottom>
              Total Images {images.length}
            </MDTypography>
            <div>
              <MDButton variant="gradient" color="dark" onClick={handleDialogOpen}>
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp; Add Image
              </MDButton>
            </div>
          </MDBox>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {images.map((item, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <Card sx={{ maxWidth: 345, p: 0 }}>
                  <CardMedia
                    component="img"
                    height="240"
                    width="100%"
                    sx={{ objectFit: "contain" }}
                    image={item.url}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      {item.description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <MDBox display="flex" justifyContent="space-between" alignItems="center">
                      <MDBox display="flex">
                        <IconButton
                          // sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                          aria-label={`info about ${item.description}`}
                          onClick={() => {
                            setDeleteId(item.id);
                            handleDeleteOpen(item.id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          // sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                          aria-label={`info about ${item.description}`}
                          onClick={() => {
                            setServiceId(item.id);
                            handleDialogOpen(item);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </MDBox>
                      <MDBox>
                        <IconButton
                          // sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                          aria-label={`info about ${item.description}`}
                          onClick={() => {
                            navigator.clipboard.writeText(item.url);
                            toast.success("image url copied to clipboard");
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </MDBox>
                    </MDBox>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
        <Dialog open={open} onClose={handleDialogOpen} fullWidth>
          <DialogTitle>{serviceId ? "Update" : "Add"} Image</DialogTitle>
          <DialogContent>
            {/* <DialogContentText></DialogContentText> */}
            <TextField
              autoFocus
              margin="dense"
              id="notification"
              label="Image Description"
              type="text"
              fullWidth
              variant="standard"
              name="description"
              onChange={onChange}
              value={data.description}
            />
            <TextField
              autoFocus
              margin="dense"
              id="notification"
              label="Tags"
              type="text"
              fullWidth
              variant="standard"
              name="tags"
              onChange={onChange}
              value={data.tags}
            />
            <TextField
              autoFocus
              margin="dense"
              id="notification"
              label="Slug"
              type="text"
              fullWidth
              variant="standard"
              name="slug"
              onChange={onChange}
              value={data.slug}
            />
            <div
              style={{
                height: "250px",
                width: "100%",
                margin: "0 auto",
                border: "1px dashed gray",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {image.length > 0 ? (
                <img
                  src={image[0].preview}
                  alt=""
                  style={{ width: "100%", objectFit: "contain", maxHeight: "90%" }}
                />
              ) : (
                <p>Drag 'n' drop some files here, or click to select files</p>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setImage([]);
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              // disabled={!newUser.first_name || !newUser.last_name || !newUser.email}
              onClick={serviceId ? _updateAsset : _uploadAsset}
            >
              {serviceId ? "Update" : "Save"} Image
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={deleteOpen} onClose={handleDeleteOpen} fullWidth>
          <DialogTitle>Delete Image</DialogTitle>

          <DialogContent>
            <DialogContentText>Are You sure you want to delete this asset</DialogContentText>{" "}
            <Typography sx={{ fontSize: "14px" }}>
              This will erase the record permanently
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteOpen}>Cancel</Button>
            <Button onClick={_deleteAsset}>Yes Delete</Button>
          </DialogActions>
        </Dialog>
      </MDBox>
    </>
  );
}
