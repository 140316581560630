import { useEffect, useState } from "react";

import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { getAsset } from "components/api";
import Images from "./Images/Images";
import VideosAssets from "./videos/Videos";
import AudioAssets from "./audio/Audio";

export default function WebAssets() {
  const [value, setValue] = React.useState(0);
  const [images, setImages] = React.useState([]);
  const [videos, setVideos] = React.useState([]);
  const [audios, setAudios] = React.useState([]);
  const handleChange = (event, newValue) => setValue(newValue);

  console.log({ videos });
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  const getAssets = async () => {
    try {
      const { data } = await getAsset("media");
      console.log({ data });
      setImages(
        data.data?.content.filter(
          (item) =>
            item.ext === "webp" ||
            item.ext === "png" ||
            item.ext === "jpg" ||
            item.ext === "jpeg" ||
            item.ext === "gif"
        )
      );
      setVideos(
        data.data?.content.filter(
          (item) =>
            item.ext === "mp4" ||
            item.ext === "mov" ||
            item.ext === "avi" ||
            item.ext === "mpeg-2" ||
            item.ext === "webm" ||
            item.ext === "mkv"
        )
      );
      setAudios(data.data?.content.filter((item) => item.ext === "mp3" || item.ext === "WAV"));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAssets();
  }, []);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox position="relative" px={2}>
          <Card
            sx={{
              py: 2,
              px: 2,
            }}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <MDTypography variant="h5" fontWeight="medium">
                  {value === 0 ? "Images" : value === 1 ? "Videos" : "Audios"}
                </MDTypography>
              </Grid>
              <Grid item xs={12} md={6} lg={6} sx={{ ml: "auto" }}>
                <AppBar position="static">
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {/* <Tab label="Images" {...a11yProps(0)} /> */}
                    <Tab label="Images" {...a11yProps(1)} />
                    <Tab label="Videos" {...a11yProps(2)} />
                    <Tab label="Audios" {...a11yProps(3)} />
                  </Tabs>
                </AppBar>
              </Grid>
            </Grid>
          </Card>
        </MDBox>
        <MDBox mb={5}>
          <TabPanel value={value} index={0}>
            <Images images={images} getAssets={getAssets} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <VideosAssets videos={videos} getAssets={getAssets} />
          </TabPanel>
          <TabPanel value={value} index={2} getAssets={getAssets}>
            <AudioAssets audios={audios} getAssets={getAssets} />
          </TabPanel>
        </MDBox>
      </DashboardLayout>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
