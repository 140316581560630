import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Radio from "@mui/material/Radio";
import MDBox from "components/MDBox";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";

const options = [
  {
    label: "Send Mail",
    value: "1",
  },
  {
    label: "Send Notification",
    value: "2",
  },
  {
    label: "Send SMS",
    value: "3",
  },
  {
    label: "Download Emails",
    value: "4",
  },
  {
    label: "Download Phone No.",
    value: "5",
  },
];
export default function SelectQuickActions() {
  const [selected, setSelected] = React.useState("");

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button
            variant="outlined"
            {...bindTrigger(popupState)}
            sx={{
              minWidth: 150,
              display: "flex",
              justifyContent: "space-between",
              p: 1.5,
              color: "#000",
              border: "#CED9E8 solid 1px",
              ":hover": {
                border: "gray solid 1px",
              },
            }}
          >
            Bulk Actions {popupState.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
          <Menu {...bindMenu(popupState)}>
            <MDBox
              sx={{
                py: 2,
                px: 2,
                minWidth: "220px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                BULK ACTIONS
              </Typography>
            </MDBox>
            {options.map((o) => (
              <MenuItem value={o.value} sx={{ p: 0 }}>
                <Radio
                  checked={selected === "10"}
                  onChange={handleChange}
                  value="a"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                {o.label}
              </MenuItem>
            ))}
            <MDBox sx={{ px: 2, py: 2 }}>
              <Button
                // color="#0230B1"
                variant="contained"
                {...bindTrigger(popupState)}
                sx={{ width: "100%", p: 1.5, color: "#fff", backgroundColor: "#0230B1" }}
              >
                Next <EastIcon sx={{ ml: 1 }} />
              </Button>
            </MDBox>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
