/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseIcon,
  collapseIconBox,
  collapseItem,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
function SidenavDropDown({ icon, name, collapse, ...rest }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  return (
    <>
      <ListItem component="li">
        <MDBox
          {...rest}
          sx={(theme) =>
            collapseItem(theme, {
              transparentSidenav,
              whiteSidenav,
              darkMode,
              sidenavColor,
            })
          }
          onClick={() => setOpen(!open)}
        >
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, {
                transparentSidenav,
                whiteSidenav,
                darkMode,
              })
            }
          >
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme)}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
              })
            }
          />
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, {
                transparentSidenav,
                whiteSidenav,
                darkMode,
              })
            }
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemIcon>
          {/* <Icon sx={(theme) => collapseIcon(theme)}></Icon> */}
        </MDBox>
      </ListItem>

      {open &&
        collapse?.map((item, i) => (
          <div key={i}>
            {item.shownav ? (
              <NavLink to={item.route}>
                <ListItem component="li" sx={{ pl: 4 }}>
                  <MDBox
                    {...rest}
                    sx={(theme) =>
                      collapseItem(theme, {
                        active: item.key === collapseName,
                        transparentSidenav,
                        whiteSidenav,
                        darkMode,
                        sidenavColor,
                      })
                    }
                  >
                    <ListItemIcon
                      sx={(theme) =>
                        collapseIconBox(theme, {
                          transparentSidenav,
                          whiteSidenav,
                          darkMode,
                          active: item.key === collapseName,
                        })
                      }
                    >
                      {typeof icon === "string" ? (
                        <Icon
                          sx={(theme) => collapseIcon(theme, { active: item.key === collapseName })}
                        >
                          {icon}
                        </Icon>
                      ) : (
                        item.icon
                      )}
                    </ListItemIcon>

                    <ListItemText
                      primary={item.name}
                      sx={(theme) =>
                        collapseText(theme, {
                          miniSidenav,
                          transparentSidenav,
                          whiteSidenav,
                          active: item.key === collapseName,
                        })
                      }
                    />
                  </MDBox>
                </ListItem>
              </NavLink>
            ) : null}
          </div>
        ))}
    </>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavDropDown.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavDropDown.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavDropDown;
