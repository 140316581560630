import { Card, Icon, TextField } from "@mui/material";
import { getAssetById, postAsset, putAsset } from "components/api";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import JoditEditor from "jodit-react";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

export default function BlogCreateEdit() {
  const query = new URLSearchParams(window.location.search);
  const id = query.get("id");

  const [Post, setPost] = useState({
    hero_image_url: null,
    description: "",
    ext: "html",
    tags: "",
    asset_type: "blog_post",
    preset_code: null,
    is_public: true,
  });
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const config = {
    readonly: false,
    height: 600,
  };

  const onChange = (e) => {
    setPost((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    setPost((prev) => ({
      ...prev,
      slug: Post.description?.replace(/\W+/g, "-").toLowerCase(),
      preset_code: Post.description?.replace(/\W+/g, "-").toLowerCase(),
    }));
  }, [Post.description]);

  useEffect(() => {
    console.log("called");
    if (id) {
      _getAssetById(id);
    }
  }, []);

  const _getAssetById = async (id) => {
    try {
      toast.loading("Loading template....");
      const { data } = await getAssetById(id);
      toast.dismiss();
      console.log({ blog: data });
      if (data.code === 200) {
        setContent(data.data?.asset.content);

        setPost({
          hero_image_url: data.data?.asset.hero_image_url,
          description: data.data?.asset.description,
          ext: data.data?.asset.ext,
          tags: data.data?.asset.tags, // use tags input
          asset_type: "blog_post",
          preset_code: data.data?.asset.preset_code,
          is_public: data.data?.asset.is_public,
        });
      } else {
        toast.error("Unknown error Occured", {
          position: "bottom-left",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addAsset = async () => {
    toast.loading("Adding template....");
    const _template = await postAsset({ ...Post, content });
    toast.dismiss();
    const message = _template?.data?.message || _template?.data?.data?.message;
    if (_template.status === 200) {
      toast.success(message);
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  const updateAsset = async () => {
    toast.loading("Adding template....");
    const _template = await putAsset({ ...Post, content, id });
    toast.dismiss();
    const message = _template?.data?.message || _template?.data?.data?.message;
    if (_template.status === 200) {
      toast.success(message);
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <div style={{ display: "block" }}>
              <Card>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <MDTypography variant="h6" gutterBottom>
                    {id ? "Update" : "Save"} Post
                  </MDTypography>
                  <div>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={id ? updateAsset : addAsset}
                      // disabled={!Post.name || !Post.content}
                    >
                      <Icon sx={{ fontWeight: "bold" }}>save</Icon>
                      &nbsp; {id ? "Update" : "Save"} post
                    </MDButton>
                  </div>
                </MDBox>
                <MDBox px={8} pb={10}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="notification"
                    label="Hero Image Url"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={onChange}
                    value={Post.hero_image_url}
                    name="hero_image_url"
                    sx={{ py: 2 }}
                  />

                  {Post.hero_image_url && (
                    <MDBox sx={{ mb: 2 }}>
                      <img style={{ height: "200px" }} src={Post.hero_image_url} alt="" />
                    </MDBox>
                  )}

                  <TextField
                    autoFocus
                    margin="dense"
                    id="notification"
                    label="Blog Title"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={onChange}
                    value={Post.description}
                    name="description"
                  />

                  <TextField
                    autoFocus
                    margin="dense"
                    id="notification"
                    label="Post Subtitle"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={onChange}
                    value={Post.tags}
                    name="tags"
                  />

                  {/* <TextField
                    autoFocus
                    margin="dense"
                    id="notification"
                    label="Post Description"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={onChange}
                    value={Post.description}
                    name="description"
                  /> */}
                  <br />

                  <MDTypography variant="button" fontWeight="regular" color="text">
                    Post
                  </MDTypography>

                  <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    tabIndex={1}
                    onBlur={(newContent) => setContent(newContent)}
                    onChange={(newContent) => {}}
                  />
                </MDBox>
              </Card>
            </div>
          </MDBox>
        </MDBox>
      </DashboardLayout>
    </div>
  );
}
