// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import { Icon, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import "./pagination.css";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { deleteAsset, getAssetById, putAsset, uploadAsset } from "components/api";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function AudioAssets({ audios, getAssets }) {
  const [id, setId] = useState();
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState(20);
  const [loading, setLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [asset, setAsset] = useState({
    slug: "",
    description: "",
    ext: "html",
    tags: "", // use tags input
    asset_type: "media",
    preset_code: "",
    is_public: true,
    app_id: "storebounty",
  });
  const [content, setContent] = useState("");

  useEffect(() => {
    setAsset((prev) => ({
      ...prev,
      slug: asset.description?.trim().split(" ").join("-"),
    }));
  }, [asset.description]);

  useEffect(() => {
    if (!open) {
      setId(null);
      setAsset({
        slug: "",
        description: "",
        ext: "others",
        tags: "", // use tags input
        asset_type: "helps_and_hints",
        preset_code: "",
        is_public: true,
        app_id: "storebounty",
      });
      setContent(null);
    }
  }, [open]);

  const handleDialogOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen((prev) => !prev);
  };

  const onChange = (e) => {
    setAsset((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const _getAssetById = async (id) => {
    try {
      toast.loading("Loading Asset....");
      const { data } = await getAssetById(id);
      toast.dismiss();
      console.log(data.data?.asset);
      if (data.code === 200) {
        setContent(data.data?.asset.content);
        setAsset({
          slug: data.data?.asset.slug,
          description: data.data?.asset.description,
          ext: data.data?.asset.ext,
          tags: data.data?.asset.tags, // use tags input
          asset_type: "blog_post",
          preset_code: data.data?.asset.preset_code,
          is_public: data.data?.asset.is_public,
          app_id: "storebounty",
        });
      } else {
        toast.error("Unknown error Occured", {
          position: "bottom-left",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const _uploadAsset = async () => {
    if (!content) {
      toast.error("content is empty");
      toast.dismiss();
      return;
    }

    toast.loading("Adding Asset....");
    const formData = new FormData();
    formData.append("file", content[0]);
    formData.append("description", asset.description);
    formData.append("ext", content[0]?.name.split(".")[1]);
    formData.append("asset_type", "media");
    formData.append("tags", asset.tags);
    formData.append("is_public", true);
    formData.append("slug", asset.slug);
    formData.append("app_id", "storebounty");
    const _template = await uploadAsset(formData);
    toast.dismiss();
    getAssets();
    console.log({ _template });
    if (_template.status === 200) {
      toast.success("successfully.");
    } else {
      toast.error("Unknown error Occured", {
        position: "bottom-left",
      });
    }
  };

  const updateAsset = async () => {
    toast.loading("Updating Asset....");
    if (content) {
      const formData = new FormData();
      formData.append("file", content[0]);
      formData.append("description", asset.description);
      formData.append("ext", content[0]?.name.split(".")[1]);
      formData.append("asset_type", "media");
      formData.append("tags", asset.tags);
      formData.append("is_public", true);
      formData.append("slug", asset.slug);
      formData.append("app_id", "storebounty");
      const _template = await putAsset(formData);
      toast.dismiss();
      getAssets();
      return;
    }
    const _template = await putAsset({ ...asset, id });
    toast.dismiss();

    const message = _template?.data?.message || _template?.data?.data?.message;
    if (_template.status === 200) {
      handleDialogOpen();
      getAssets();
      toast.success(message);
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  const _deleteAsset = async () => {
    toast.loading("Deleting Asset....");
    const _template = await deleteAsset(deleteId);
    toast.dismiss();
    handleDeleteOpen();
    getAssets();
    setDeleteId("");
    const message = _template?.data?.message || _template?.data?.data?.message;
    if (_template.status === 200) {
      handleDialogOpen();
      getAssets();
      toast.success(message);
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  return (
    <>
      <MDBox py={3}>
        <MDBox>
          <div style={{ display: "block" }}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDTypography variant="h6" gutterBottom></MDTypography>
                <div>
                  <MDButton variant="gradient" color="dark" onClick={handleDialogOpen}>
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp; Audio
                  </MDButton>
                </div>
              </MDBox>
              {/* {loading ? (
                <Holder />
              ) : (
                <DataTable
                  className="hidden"
                  table={{ columns, rows }}
                  showTotalEntries={false}
                  isSorted={false}
                  size={size}
                  noEndBorder
                  entriesPerPage={false}
                  style={{ display: "none" }}
                  pagination={false}
                />
              )} */}
              <MDBox sx={{ p: 3 }}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                  {audios.map((audio, index) => (
                    <Grid item xs={2} sm={4} md={4} key={index}>
                      <Paper>
                        <audio width="100%" height="150" controls>
                          <source src={audio.url} type={`audio/${audio.ext}`} />
                          Your browser does not support the audio tag.
                        </audio>
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          p={2}
                        >
                          <Typography>{audio.description}</Typography>
                          <MDBox>
                            <IconButton
                              aria-label={`info about ${audio.description}`}
                              onClick={() => {
                                setDeleteId(audio.id);
                                handleDeleteOpen(audio.id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <IconButton
                              // sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                              aria-label={`info about ${audio.description}`}
                              onClick={() => {
                                navigator.clipboard.writeText(audio.url);
                                toast.success("Video url copied to clipboard");
                              }}
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </MDBox>
                        </MDBox>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </MDBox>
            </Card>
          </div>
        </MDBox>
      </MDBox>

      <Dialog open={open} onClose={handleDialogOpen} fullWidth>
        <DialogTitle>{id ? "Update" : "Create"} Video</DialogTitle>
        <DialogContent sx={{ backgroundColor: "white" }}>
          {/* <DialogContentText></DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
            name="description"
            value={asset.description}
            onChange={onChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="notification"
            label="Tags"
            type="text"
            fullWidth
            variant="standard"
            name="tags"
            value={asset.tags}
            onChange={onChange}
          />

          <TextField
            autoFocus
            margin="dense"
            id="notification"
            label="Slug"
            type="text"
            fullWidth
            variant="standard"
            name="slug"
            value={asset.slug}
            onChange={onChange}
          />

          <input
            autoFocus
            margin="dense"
            id="notification"
            label="Asset"
            type="file"
            fullWidth
            variant="standard"
            name="content"
            // value={content}
            accept=".mp3,audio/*"
            onChange={(e) => setContent(e.target.files)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            // disabled={!asset.first_name || !asset.last_name || !asset.email}
            onClick={id ? updateAsset : _uploadAsset}
          >
            {id ? "update" : "Save"} Video
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteOpen} onClose={handleDeleteOpen} fullWidth>
        <DialogTitle>Delete Help / Hint</DialogTitle>

        <DialogContent>
          <DialogContentText>Are You sure you want to delete this asset</DialogContentText>{" "}
          <Typography sx={{ fontSize: "14px" }}>This will erase the record permanently</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteOpen}>Cancel</Button>
          <Button onClick={_deleteAsset}>Yes Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AudioAssets;
