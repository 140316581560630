import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Icon } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { deleteAsset } from "components/api";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import useAsync from "hooks/useAsync";
import useFilter from "hooks/useFilter";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AssetServices from "services/assetServices";
export default function Index() {
  const [assets, setAssets] = useState([]);
  const [serviceId, setServiceId] = useState("");
  const [open, setOpen] = useState(false);
  const [firstRun, setFirstRun] = useState(true);

  const handleDialogOpen = (id) => {
    if (serviceId) {
      setServiceId("");
    } else {
      setServiceId(id);
    }
    setOpen((prev) => !prev);
  };

  const {
    changeSize,
    page,
    size,
    pageCount,
    setPageCount,
    total,
    setTotal,
    search,
    setSearch,
    handlePageClick,
  } = useFilter();
  const { data, loading, setIsUpdated } = useAsync(AssetServices.getAsset, {
    params: "custom_page",
    page,
    size,
    search,
    setPageCount,
    setTotal,
  });

  useEffect(() => {
    const getTemplates = async () => {
      try {
        setAssets(data.data?.data?.content);
      } catch (err) {
        console.log(err);
      }
    };

    if (data.data?.data?.content) {
      getTemplates();
    }
  }, [data]);

  const rows = assets.map((item) => ({
    ...item,
    action: (
      <div>
        <a href={item.url} target="_blank" rel="noreferrer">
          <Button>
            <OpenInNewIcon sx={{ color: "gray" }} />
          </Button>
        </a>
        <Link to={`/custom-pages/edit?id=${item.id}`}>
          <Button>
            <EditIcon sx={{ color: "gray" }} />
          </Button>
        </Link>

        <Button onClick={() => handleDialogOpen(item.id)}>
          <DeleteIcon sx={{ color: "red" }} />
        </Button>
      </div>
    ),
  }));

  const columns = [
    { Header: "Title", accessor: "description", align: "left" },
    { Header: "Type", accessor: "tags" },
    { Header: "", accessor: "action", align: "right" },
  ];

  // const getAssets = async () => {
  //   try {
  //     const { data } = await getAsset("custom_page");
  //     setAssets(data.data?.content);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const _deleteAsset = async () => {
    toast.loading("Deleting template....");
    const _template = await deleteAsset(serviceId);
    toast.dismiss();
    handleDialogOpen();
    setIsUpdated();
    if (_template.status === 200) {
      toast.success("successfully.");
    } else {
      toast.error("Unknown error Occured", {
        position: "bottom-left",
      });
    }
  };

  // useEffect(() => {
  //   if (firstRun) {
  //     setFirstRun(false);
  //     getAssets();
  //   }
  // }, [firstRun]);
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <div style={{ display: "block" }}>
              <MDBox p={3} display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography variant="h6" gutterBottom>
                  Custom Pages
                </MDTypography>

                <Link to="/custom-pages/create">
                  <MDButton variant="gradient" color="dark">
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp; Create New
                  </MDButton>
                </Link>
              </MDBox>
              <MDBox p={3}>
                <DataTable
                  className="hidden"
                  table={{ columns, rows }}
                  showTotalEntries={false}
                  isSorted={false}
                  size={size}
                  noEndBorder
                  entriesPerPage={false}
                  style={{ display: "none" }}
                  pagination={false}
                />
              </MDBox>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDTypography variant="h6" gutterBottom>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<i className="fa fa-angle-double-right"></i>}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel={<i className="fa fa-angle-double-left"></i>}
                    containerClassName="pagination"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    renderOnZeroPageCount={false}
                  />
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems="center"
                  lineHeight={0}
                  justifyContent="space-between"
                >
                  <div>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;Showing <strong>{data.length} </strong> users of {total}
                    </MDTypography>
                  </div>
                  <div>
                    <select
                      className="select-size"
                      onChange={(e) => changeSize(e.target.value)}
                      value={size}
                    >
                      <option value="">Page Size</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </MDBox>
              </MDBox>
            </div>
          </MDBox>
        </MDBox>
      </DashboardLayout>
      <Dialog open={open} onClose={handleDialogOpen} fullWidth>
        <DialogTitle>Delete Page</DialogTitle>

        <DialogContent>
          <DialogContentText>Are You sure you want to delete this Page</DialogContentText>{" "}
          <Typography sx={{ fontSize: "14px" }}>This will erase the record permanently</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={_deleteAsset}>Yes Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
