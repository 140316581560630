import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Button, Card, Icon, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { deleteEmailTemplates, getEmailTemplatesById } from "components/api";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import useAsync from "hooks/useAsync";
import useEmailTest from "hooks/useEmailTest";
import useFilter from "hooks/useFilter";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import EmailServices from "services/EmailServices";
export default function Emailing() {
  const [templates, setTemplates] = useState([]);
  const [open, setOpen] = useState(false);

  const {
    changeSize,
    page,
    size,
    pageCount,
    setPageCount,
    total,
    setTotal,
    search,
    handlePageClick,
  } = useFilter();
  const { data, loading, setIsUpdated } = useAsync(EmailServices.getEmailTemplates, {
    page,
    size,
    search,
    setPageCount,
    setTotal,
  });

  useEffect(() => {
    const getTemplates = async () => {
      try {
        setTemplates(data.data?.data?.content?.sort((a, b) => a.deletable - b.deletable));
      } catch (err) {
        console.log(err);
      }
    };

    if (data.data?.data?.content) {
      getTemplates();
    }
  }, [data]);

  const [serviceId, setServiceId] = useState("");
  const { tokens, setTokens, tstData, setTstData, tstOpen, setTstOpen, onSubmit } = useEmailTest();

  const handleTstChange = (e) => {
    setTstData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleTokenChange = (e) => {
    setTstData((prev) => ({
      ...prev,
      tokens: { ...prev.tokens, [e.target.name]: e.target.value },
    }));
  };
  const handleDialogOpen = (id) => {
    if (serviceId) {
      setServiceId("");
      setTstData({
        to: "",
        subject: "",
        message: "",
      });
      setTokens([]);
    } else {
      setServiceId(id);
    }
    setOpen((prev) => !prev);
  };

  const handleTstOpen = (id) => {
    setTstOpen((prev) => !prev);
  };

  const rows = templates?.map((item) => ({
    ...item,
    action: (
      <div>
        <Button
          onClick={() => {
            getTemplateById(item.id);
            handleTstOpen(item.id);
          }}
        >
          <EmailIcon sx={{ color: "gray" }} />
        </Button>
        <Link to={`/emailing/edit?id=${item.id}`}>
          <Button>
            <EditIcon sx={{ color: "gray" }} />
          </Button>
        </Link>
        {item.deletable && (
          <Button onClick={() => handleDialogOpen(item.id)}>
            <DeleteIcon sx={{ color: "red" }} />
          </Button>
        )}
      </div>
    ),
  }));

  const columns = [
    {
      Header: "description",
      accessor: "description",
      align: "left",
      Cell: ({ row }) => {
        const { original } = row;
        const res = original.description && (
          <span style={{ display: "flex", alignItems: "center", gap: 4 }}>
            {!original.deletable && <VerifiedIcon />}

            {original.description}
          </span>
        );
        return res;
      },
    },
    { Header: "Preset code", accessor: "preset_code", align: "left" },
    { Header: "", accessor: "action", align: "right" },
  ];

  const deleteTemplate = async () => {
    toast.loading("Deleting template....");
    const _template = await deleteEmailTemplates(serviceId);
    toast.dismiss();
    handleDialogOpen();
    setIsUpdated(true);
    if (_template.status === 200) {
      toast.success("successfully.");
    } else {
      toast.error("Unknown error Occured", {
        position: "bottom-left",
      });
    }
  };

  const getTemplateById = async (id) => {
    try {
      toast.loading("Loading template....");
      const { data } = await getEmailTemplatesById(id);
      toast.dismiss();
      if (data.code === 200) {
        setTstData({
          preset_code: data.data?.asset.preset_code,
          message: data.data?.asset.content,
        });
        setTokens(data.data?.asset?.tokens.split(","));
      } else {
        toast.error("Unknown error Occured", {
          position: "bottom-left",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <div style={{ display: "block" }}>
              <Card>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <MDTypography variant="h6" gutterBottom>
                    Email Templates
                  </MDTypography>
                  <div>
                    <Link to="/emailing/create">
                      <MDButton variant="gradient" color="dark">
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                        &nbsp; Email template
                      </MDButton>{" "}
                    </Link>
                  </div>
                </MDBox>
                <DataTable
                  className="hidden"
                  table={{ columns, rows }}
                  showTotalEntries={false}
                  isSorted={false}
                  size={size}
                  noEndBorder
                  entriesPerPage={false}
                  style={{ display: "none" }}
                  pagination={false}
                />
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <MDTypography variant="h6" gutterBottom>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={<i className="fa fa-angle-double-right"></i>}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel={<i className="fa fa-angle-double-left"></i>}
                      containerClassName="pagination"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      activeClassName="active"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      renderOnZeroPageCount={false}
                    />
                  </MDTypography>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    lineHeight={0}
                    justifyContent="space-between"
                  >
                    <div>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;Showing <strong>{data.length} </strong> users of {total}
                      </MDTypography>
                    </div>
                    <div>
                      <select
                        className="select-size"
                        onChange={(e) => changeSize(e.target.value)}
                        value={size}
                      >
                        <option value="">Page Size</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </MDBox>
                </MDBox>
              </Card>
            </div>
          </MDBox>
        </MDBox>
      </DashboardLayout>
      <Dialog open={open} onClose={handleDialogOpen} fullWidth>
        <DialogTitle>Delete Email Template</DialogTitle>

        <DialogContent>
          <DialogContentText>Are You sure you want to delete this template</DialogContentText>{" "}
          <Typography sx={{ fontSize: "14px" }}>This will erase the record permanently</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={deleteTemplate}>Yes Delete</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={tstOpen} onClose={handleTstOpen} fullWidth>
        <DialogTitle>Testing: {tstData.preset_code}</DialogTitle>
        <MDBox sx={{ px: 2 }}>
          {/* <Typography variant="body2"></Typography> */}
          <Typography sx={{ fontSize: 13 }}>Please supply all values</Typography>
        </MDBox>
        <DialogContent>
          <TextField
            fullWidth
            sx={{ mt: 0 }}
            label="Recipients (:to)"
            name="recipients"
            value={tstData.recipients}
            onChange={handleTstChange}
          />

          {tokens?.map((token) => (
            <TextField
              fullWidth
              sx={{ mt: 4 }}
              label={token}
              name={token}
              value={tokens?.token}
              onChange={handleTokenChange}
            />
          ))}

          <TextField
            fullWidth
            sx={{ mt: 4 }}
            label="Subject"
            name="subject"
            value={tstData.subject}
            onChange={handleTstChange}
          />
          <TextField
            fullWidth
            sx={{ mt: 4 }}
            label="message"
            name="message"
            value={tstData.message}
            onChange={handleTstChange}
            multiline
            rows={6}
            disabled
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTstOpen(false)}>Cancel</Button>
          <Button onClick={onSubmit}>Send Mail</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
