import UIContext from "context/UIContext";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import UserServices from "services/UserServices";

export default function useAttributeSubmit({ id }) {
  const [attribute, setAttributes] = useState();
  const [open, setOpen] = useState(false);
  const { setIsUpdated } = useContext(UIContext);
  const [openDelete, setOpenDelete] = useState();
  const [serviceId, setServiceID] = useState(null);

  const closeDelete = () => {
    setOpenDelete(false);
    setAttributes(null);
  };
  const handleAttributeChange = (e) => {
    setAttributes((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSubmit = async (e) => {
    try {
      if (id) {
        const res = await UserServices.updateAttribute(id, attribute);
        const message = res?.data?.message || res?.data?.data?.message;
        toast.success(message);
        setOpen(false);
        return;
      }
      const res = await UserServices.addAttribute(id, attribute);
      const message = res?.data?.message || res?.data?.data?.message;
      toast.success(message);
      setOpen(false);
    } catch (err) {
      const message = err?.responses?.data || err?.message || err?.msg;
      toast.error(message);
    } finally {
      setIsUpdated(true);
      setAttributes();
    }
  };

  const deleteAsset = async () => {
    try {
      const res = await UserServices.deleteAttribute(id, attribute);
      const message = res?.data?.message || res?.data?.data?.message;
      toast.success(message);
      setOpenDelete(false);
      return;
    } catch (err) {
      const message = err?.responses?.data || err?.message || err?.msg;
      toast.error(message);
    } finally {
      setIsUpdated(true);
      setAttributes();
    }
  };

  return {
    attribute,
    setAttributes,
    handleAttributeChange,
    open,
    setOpen,
    handleSubmit,
    openDelete,
    setOpenDelete,
    deleteAsset,
    serviceId,
    setServiceID,
    closeDelete,
  };
}
