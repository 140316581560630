import { Card, Icon, TextField } from "@mui/material";
import { getEmailTemplatesById, postEmailTemplates, putEmailTemplates } from "components/api";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import JoditEditor from "jodit-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";

export default function EmailCreateEdit({ placeholder }) {
  const query = new URLSearchParams(window.location.search);
  const id = query.get("id");
  const [tokens, setTokens] = useState("");
  const [template, setTemplate] = useState({
    description: "",
    ext: "html",
    asset_type: "email_template",
    preset_code: "",
    is_public: false,
    deletable: false,
  });
  const editor = useRef(null);
  const [content, setContent] = useState("");
  // const [placeholder, setPlaceholder] = useState("content here");

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/,
      placeholder: placeholder || "Start typings...",
      height: 600,
    }),
    [placeholder]
  );

  const [firstLoad, setFirstLoad] = useState(true);

  const onChange = (e) => {
    setTemplate((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (!id) return;
    getTemplateById(id);
    setFirstLoad(false);
  }, []);

  const getTemplateById = async (id) => {
    try {
      toast.loading("Loading template....");
      const { data } = await getEmailTemplatesById(id);
      toast.dismiss();
      console.log(data.data?.asset);
      if (data.code === 200) {
        setContent(data.data?.asset.content);
        setTemplate({
          description: data.data?.asset.description,
          ext: data.data?.asset.ext,
          asset_type: "email_template",
          preset_code: data.data?.asset.preset_code,
          is_public: data.data?.asset.is_public,
          deletable: data.data?.asset.deletable,
        });
        setTokens(data.data?.asset?.tokens);
      } else {
        toast.error("Unknown error Occured", {
          position: "bottom-left",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addTemplate = async () => {
    toast.loading("Adding template....");
    const _template = await postEmailTemplates({ ...template, content, tokens });
    toast.dismiss();
    console.log(template);
    const message = _template?.data?.message || _template?.data?.data?.message;
    if (_template.status === 200) {
      toast.success(message);
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  const updateTemplate = async () => {
    toast.loading("Updating Email template....");

    const _template = await putEmailTemplates({ ...template, content, id, tokens });
    toast.dismiss();

    const message = _template?.data?.message || _template?.data?.data?.message;
    if (_template.status === 200) {
      toast.success(message);
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <div style={{ display: "block" }}>
              <Card>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <MDTypography variant="h6" gutterBottom>
                    {id ? "Update Email" : "Add Email"} Template
                  </MDTypography>
                  <div>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={id ? updateTemplate : addTemplate}
                    >
                      <Icon sx={{ fontWeight: "bold" }}>save</Icon>
                      &nbsp; {id ? "Update" : "Save"} Template
                    </MDButton>
                  </div>
                </MDBox>
                <MDBox px={8} pb={10}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="preset_code"
                    label="Unique Preset Code"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={onChange}
                    value={template.preset_code}
                    name="preset_code"
                    disabled={id && !template.deletable}
                  />

                  <TextField
                    autoFocus
                    margin="dense"
                    id="tokens"
                    label="Tokens (Tokens should be between '#' and in capital letters and comma seperated)"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setTokens(e.target.value)}
                    value={tokens}
                    name="#"
                    disabled={id && !template.deletable}
                  />

                  <TextField
                    autoFocus
                    margin="dense"
                    id="description"
                    label="Template Description"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={onChange}
                    value={template.description}
                    name="description"
                  />

                  <br />

                  <MDTypography variant="button" fontWeight="regular" color="text">
                    Template
                  </MDTypography>

                  <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    tabIndex={1}
                    onBlur={(newContent) => setContent(newContent)}
                    onChange={(newContent) => {}}
                  />
                </MDBox>
              </Card>
            </div>
          </MDBox>
        </MDBox>
      </DashboardLayout>
    </div>
  );
}
