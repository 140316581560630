import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Billing from "layouts/billing";
import Dashboard from "layouts/dashboard";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import RTL from "layouts/rtl";
import Tables from "layouts/tables";
import Users from "layouts/users";
import Admins from "layouts/users/admins";
import Apps from "layouts/users/apps";
import Businesses from "layouts/users/businesses";
import UsersDetails from "layouts/users/detail";

// @mui icons
import Icon from "@mui/material/Icon";
import Assets from "layouts/assets/";
import { Logout } from "layouts/authentication/logout";
import Blogs from "layouts/blogs";
import BlogCreateEdit from "layouts/blogs/BlogCreateEdit";
import Emailing from "layouts/emailing";
import EmailCreateEdit from "layouts/emailing/EmailCreateEdit";
import HintsAndHelp from "layouts/helps_and_hints/HelpsAndHint";
import Settings from "layouts/settings";
import Tickets from "layouts/tickets";
import SupportTicket from "layouts/tickets/ticket";

import Media from "layouts/assets/media";
import Others from "layouts/assets/others/Others";
import WebAssets from "layouts/assets/web";
import CSSAddEdit from "layouts/assets/web/css/CssAddEdit";
import HTMLAddEdit from "layouts/assets/web/html/HTMLAddEdit";
import ScriptsAndEdit from "layouts/assets/web/script/ScriptAddEdit";
import CustomPages from "layouts/custom-pages";
import CustomPageCreateEdit from "layouts/custom-pages/CustompageAddEdit";
import SendEmail from "layouts/emailing/SendEmail";
import FeedsIndex from "layouts/feeds";
import FeedsCreateEdit from "layouts/feeds/FeedsCreateEdit";
import HelpsCreateEdit from "layouts/helps_and_hints/HelpsAddEdit";
import PushNotifications from "layouts/notifications/PushNotifications";
import SmsNotifications from "layouts/notifications/SmsNotifications";
import Groups from "layouts/settings//group/Groups";
import AppSettings from "layouts/settings/AppSettings";
import GroupEdit from "layouts/settings/group/GroupEdit";
import Permissions from "layouts/settings/permissions/permissions";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    shownav: true,
    admin: true,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">people_outline</Icon>,
    route: "/users",
    component: <Users />,
    shownav: true,
    admin: true,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">people_outline</Icon>,
    route: "/users/:id",
    component: <UsersDetails />,
    shownav: false,
    admin: true,
  },
  {
    type: "collapse",
    name: "Businesses",
    key: "businesses",
    icon: <Icon fontSize="small">store</Icon>,
    route: "/businesses",
    component: <Businesses />,
    shownav: true,
    admin: true,
  },
  {
    type: "collapse",
    name: "Admin Users",
    key: "admin-users",
    icon: <Icon fontSize="small">people_outline</Icon>,
    route: "/admin-users",
    component: <Admins />,
    shownav: true,
    admin: true,
  },
  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "RTL",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/rtl",
    component: <RTL />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
    shownav: true,
    admin: true,
    collapse: [
      {
        type: "collapse",
        name: "Push",
        key: "notifications",
        icon: <Icon fontSize="small">notifications</Icon>,
        route: "/notifications",
        component: <Notifications />,
        shownav: true,
        admin: true,
      },
      {
        type: "collapse",
        name: "Email",
        key: "notifications/email",
        icon: <Icon fontSize="small">email</Icon>,
        route: "/notifications/email",
        component: <PushNotifications />,
        shownav: true,
        admin: true,
      },
      {
        type: "collapse",
        name: "Email Create",
        key: "Email_templates",
        icon: <Icon fontSize="small">chat</Icon>,
        route: "/notifications/send-email",
        component: <SendEmail />,
      },
      {
        type: "collapse",
        name: "Sms",
        key: "notifications/sms",
        icon: <Icon fontSize="small">sms</Icon>,
        route: "/notifications/sms",
        component: <SmsNotifications />,
        shownav: true,
        admin: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Contents",
    key: "contents",
    icon: <Icon fontSize="small">dashboard</Icon>,
    component: <Dashboard />,
    shownav: true,
    admin: true,
    collapse: [
      {
        type: "collapse",
        name: "Email Templates",
        key: "emailing",
        icon: <Icon fontSize="small">email</Icon>,
        route: "/emailing",
        component: <Emailing />,
        shownav: true,
        admin: true,
      },
      {
        type: "collapse",
        name: "Email Create",
        key: "Email_templates",
        icon: <Icon fontSize="small">chat</Icon>,
        route: "/emailing/:id",
        component: <EmailCreateEdit />,
      },
      {
        type: "collapse",
        name: "Help and Hints",
        key: "helps",
        icon: <Icon fontSize="small">help</Icon>,
        route: "/helps",
        component: <HintsAndHelp />,
        shownav: true,
        admin: true,
      },
      {
        type: "collapse",
        name: "Help and Hints",
        key: "helps",
        icon: <Icon fontSize="small">help</Icon>,
        route: "/helps/:id",
        component: <HelpsCreateEdit />,
      },
      {
        type: "collapse",
        name: "Blogs",
        key: "blogs",
        icon: <Icon fontSize="small">assignment</Icon>,
        route: "/blogs",
        component: <Blogs />,
        shownav: true,
        admin: true,
      },
      {
        type: "collapse",
        name: "Blog Create",
        key: "Blog-create",
        icon: <Icon fontSize="small">chat</Icon>,
        route: "/blog/:id",
        component: <BlogCreateEdit />,
      },
      {
        type: "collapse",
        name: "Feeds",
        key: "feeds",
        icon: <Icon fontSize="small">feed</Icon>,
        route: "/feeds",
        component: <FeedsIndex />,
        shownav: true,
        admin: true,
      },
      {
        type: "collapse",
        name: "Feed Create",
        key: "feed-create",
        icon: <Icon fontSize="small">feed</Icon>,
        route: "/feeds/:id",
        component: <FeedsCreateEdit />,
      },
      {
        type: "collapse",
        name: "Custom Pages",
        key: "custom-pages",
        icon: <Icon fontSize="small">assignment</Icon>,
        route: "/custom-pages",
        component: <CustomPages />,
        shownav: true,
        admin: true,
      },
      {
        type: "collapse",
        name: "Custom-page Create",
        key: "Custom-page-create",
        icon: <Icon fontSize="small">chat</Icon>,
        route: "/custom-pages/:id",
        component: <CustomPageCreateEdit />,
      },
    ],
  },

  {
    type: "collapse",
    name: "Tickets",
    key: "tickets",
    icon: <Icon fontSize="small">chat</Icon>,
    route: "/tickets",
    component: <Tickets />,
    shownav: true,
    admin: true,
  },
  {
    type: "collapse",
    name: "Tickets",
    key: "ticketsId",
    icon: <Icon fontSize="small">chat</Icon>,
    route: "/ticket/:id",
    component: <SupportTicket />,
  },
  {
    type: "collapse",
    name: "Apps",
    key: "apps",
    icon: <Icon fontSize="small">apps</Icon>,
    route: "/apps",
    component: <Apps />,
    admin: true,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Assets",
    key: "assets",
    icon: <Icon fontSize="small">folder</Icon>,
    // route: "/assets",
    component: <Assets />,
    shownav: true,
    admin: true,
    collapse: [
      {
        type: "collapse",
        name: "Web",
        key: "assets/web",
        icon: <Icon fontSize="small">code</Icon>,
        route: "/assets/web",
        component: <WebAssets />,
        shownav: true,
        admin: true,
      },
      {
        type: "collapse",
        name: "Media",
        key: "assets/media",
        icon: <Icon fontSize="small">image</Icon>,
        route: "/assets/media",
        component: <Media />,
        shownav: true,
        admin: true,
      },
      {
        type: "collapse",
        name: "Scripts",
        key: "assets/scripts/:id",
        icon: <Icon fontSize="small">javascript</Icon>,
        route: "/assets/scripts/:id",
        component: <ScriptsAndEdit />,
        admin: true,
      },
      {
        type: "collapse",
        name: "CSS",
        key: "assets/css/:id",
        icon: <Icon fontSize="small">css</Icon>,
        route: "/assets/css/:id",
        component: <CSSAddEdit />,
        admin: true,
      },

      {
        type: "collapse",
        name: "HTML",
        key: "assets/html/:id",
        icon: <Icon fontSize="small">html</Icon>,
        route: "/assets/html/:id",
        component: <HTMLAddEdit />,
        admin: true,
      },
      {
        type: "collapse",
        name: "Others",
        key: "assets/others",
        icon: <Icon fontSize="small">webAsset</Icon>,
        route: "/assets/others",
        component: <Others />,
        shownav: true,
        admin: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/login",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/settings",
    component: <Settings />,
    shownav: true,
    admin: true,
    collapse: [
      {
        type: "collapse",
        name: "Groups",
        key: "groups",
        icon: <Icon fontSize="small">group</Icon>,
        route: "/settings/groups",
        component: <Groups />,
        shownav: true,
        admin: true,
      },
      {
        type: "collapse",
        name: "Groups",
        key: "groups",
        icon: <Icon fontSize="small">settings</Icon>,
        route: "/settings/groups/:id",
        component: <GroupEdit />,

        admin: true,
      },
      {
        type: "collapse",
        name: "Permission",
        key: "groups",
        icon: <Icon fontSize="small">shield</Icon>,
        route: "/settings/permission",
        component: <Permissions />,
        shownav: true,
        admin: true,
      },
      {
        type: "collapse",
        name: "App Settings",
        key: "settings/app",
        icon: <Icon fontSize="small">lock</Icon>,
        route: "/settings/app",
        component: <AppSettings />,
        shownav: true,
        admin: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Logout",
    key: "logout",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/logout",
    component: <Logout />,
    shownav: true,
    // admin: true,
  },
];

export default routes;
