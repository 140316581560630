import requests, { Axios } from "components/api";

const UserServices = {
  async getUsers(_, { page, size, search = "" }) {
    const res = await requests.get(
      `/accounts/api/v1/report/users?page=${page}&size=${size}&q=${search}`
    );
    return res;
  },

  async postUser(data) {
    const payload = {
      app_id: "storebounty",
      ...data,
    };
    const res = await requests.post(`/accounts/api/v1/auth/users/register`, payload);
    return res;
  },

  async getById(user_id) {
    const res = await requests.get(`/accounts/api/v1/report/users/${user_id}`);
    return res;
  },

  async addAttribute(user_id, payload) {
    const res = await requests.post(`/accounts/api/v1/report/users/${user_id}/attributes`, payload);
    return res;
  },

  async updateAttribute(user_id, payload) {
    const res = await requests.post(`/accounts/api/v1/report/users/${user_id}/attributes`, payload);
    return res;
  },

  async deleteAttribute(user_id, payload) {
    const res = await Axios.delete(`/accounts/api/v1/report/users/${user_id}/attributes`, {
      data: {
        ...payload,
      },
    });
    return res;
  },
};

export default UserServices;
