import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { getSettings, putSettings } from "../../components/api";
import { useEffect } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";

export default function AppSettings() {
  const [loading, setLoading] = useState(false);

  const [settings, setSettings] = useState({
    name: "",
    domain: "",
    status: "active",
    client_secret: "",
    jwt_token_secret: "",
    google_oauth_client_id: "",
    google_oauth_secret: "",
    google_oauth_client_id_ios: "",
    google_oauth_secret_ios: null,
    google_oauth_client_id_android: "",
    google_oauth_secret_android: null,
    app_token_expiry_min: 0,
    user_token_expiry_min: 0,
  });

  const onChange = (e) => {
    setSettings((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const _getSettings = async () => {
    try {
      const { data } = await getSettings();
      setLoading(false);
      console.log({ data });
      if (data.code === 200) {
        setSettings({
          name: data?.data?.settings?.name,
          domain: data?.data?.settings?.domain,
          status: data?.data?.settings?.status,
          client_secret: data?.data?.settings?.client_secret,
          jwt_token_secret: data?.data?.settings?.jwt_token_secret,
          google_oauth_client_id: data?.data?.settings?.google_oauth_client_id,
          google_oauth_secret: data?.data?.settings?.google_oauth_secret,
          google_oauth_client_id_ios: data?.data?.settings?.google_oauth_client_id_ios,
          google_oauth_secret_ios: data?.data?.settings?.google_oauth_secret_ios,
          google_oauth_client_id_android: data?.data?.settings?.google_oauth_client_id_android,
          google_oauth_secret_android: data?.data?.settings?.google_oauth_secret_android,
          app_token_expiry_min: data?.data?.settings?.app_token_expiry_min,
          user_token_expiry_min: data?.data?.settings?.user_token_expiry_min,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateSettings = async () => {
    toast.loading("Adding template....");
    const res = await putSettings(settings);
    toast.dismiss();
    const message = res?.data?.message || res?.data?.data?.message;
    if (res.status === 200) {
      getSettings();
      toast.success(message);
    } else {
      toast.error(message, {
        position: "bottom-left",
      });
    }
  };

  useEffect(() => {
    _getSettings();
  }, []);

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox sx={{ mt: 3, px: 2 }}>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" pb={3}>
            <MDTypography variant="h6" gutterBottom>
              App Settings
            </MDTypography>
            <div>
              <MDButton variant="gradient" color="dark" onClick={() => updateSettings()}>
                Save Seetings
              </MDButton>
            </div>
          </MDBox>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              onChange={onChange}
              fullWidth
              label="Name"
              variant="outlined"
              name="name"
              value={settings.name}
              disabled
            />
            <TextField
              onChange={onChange}
              fullWidth
              label="Domain"
              variant="outlined"
              name="domain"
              value={settings.domain}
            />
            {/* <TextField
              onChange={onChange}
              fullWidth
              label="Status"
              variant="outlined"
              name="status"
              value={settings.status}
            /> */}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={settings.status}
                label="Status"
                onChange={onChange}
                sx={{ py: 1.5 }}
              >
                <MenuItem value={"active"}>active</MenuItem>
                <MenuItem value={"maintainance"}>maintainance</MenuItem>
              </Select>
            </FormControl>
            <TextField
              onChange={onChange}
              fullWidth
              label="client Secret"
              variant="outlined"
              name="client_secret"
            />
            <TextField
              onChange={onChange}
              fullWidth
              label="Jwt Token Secret"
              variant="outlined"
              name="jwt_token_secret"
              value={settings.jwt_token_secret}
            />
            <TextField
              onChange={onChange}
              fullWidth
              label="google_oauth_client_id"
              variant="outlined"
              name="google_oauth_client_id"
              value={settings.google_oauth_client_id}
            />
            <TextField
              onChange={onChange}
              fullWidth
              label="google_oauth_secret"
              variant="outlined"
              name="google_oauth_secret"
              value={settings.google_oauth_secret}
            />
            <TextField
              onChange={onChange}
              fullWidth
              label="google_oauth_client_id_ios"
              variant="outlined"
              name="google_oauth_client_id_ios"
              value={settings.google_oauth_client_id_ios}
            />
            <TextField
              onChange={onChange}
              fullWidth
              label="google_oauth_secret_ios"
              variant="outlined"
              name="google_oauth_secret_ios"
              value={settings.google_oauth_secret_ios}
            />
            <TextField
              onChange={onChange}
              fullWidth
              label="google_oauth_client_id_android"
              variant="outlined"
              name="google_oauth_client_id_android"
              value={settings.google_oauth_client_id_android}
            />
            <TextField
              onChange={onChange}
              fullWidth
              label="google_oauth_secret_android"
              variant="outlined"
              name="google_oauth_secret_android"
              value={settings.google_oauth_secret_android}
            />
            <TextField
              onChange={onChange}
              fullWidth
              label="app_token_expiry_min"
              variant="outlined"
              name="app_token_expiry_min"
              value={settings.app_token_expiry_min}
            />
            <TextField
              onChange={onChange}
              fullWidth
              label="user_token_expiry_min"
              variant="outlined"
              name="user_token_expiry_min"
              value={settings.user_token_expiry_min}
            />
          </Box>
        </MDBox>
      </DashboardLayout>
    </div>
  );
}
